import React from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui";
import { useTranslate } from "@/hooks";
import { cn } from "@/helpers/tailwind";
import isNil from "lodash.isnil";

interface WithTooltipProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  tooltipOffset?: number;
  tooltipClassName?: string;
  tooltipContent: string | React.ReactNode;
  onClick?: () => void;
}

const WithTooltip: React.FC<WithTooltipProps> = ({
  children,
  tooltipClassName,
  tooltipContent,
  tooltipOffset = 5,
  onClick,
  ...other
}) => {
  const t = useTranslate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClicked, setIsClicked] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = React.useCallback(() => {
    setIsClicked(true);
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClicked(false);
    }, 1000);
  }, [tooltipContent]);

  return (
    <TooltipProvider>
      <Tooltip
        open={isOpen && !isNil(tooltipContent) && (isHovered || isClicked)}
        onOpenChange={(open) => {
          if (!isClicked) setIsOpen(open);
        }}>
        <TooltipTrigger
          asChild
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <button
            onClick={() => {
              onClick?.();
              handleClick();
            }}
            {...other}>
            {children}
          </button>
        </TooltipTrigger>
        <TooltipContent
          sideOffset={tooltipOffset}
          className={cn(
            tooltipClassName,
            "border border-slate-400 bg-slate-700 text-xs text-slate-300"
          )}>
          {React.isValidElement(tooltipContent) ? (
            tooltipContent
          ) : (
            <span className="font-geist text-xs font-bold">
              {tooltipContent}
            </span>
          )}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default WithTooltip;
