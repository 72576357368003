import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import Root from "../root";
import ProtectedRoute from "@/components/auth/ProtectedRoute";
import HypeBoardsPage from "@/pages/hype-boards/HypeBoardsPage";
import HypeBoardPage from "@/pages/hype-boards/HypeBoardPage";

const HomePage = lazy(() => import("@/pages/home/HomePage"));
const BattlesPage = lazy(() => import("@/pages/battles/BattlesPage"));
const BattlePage = lazy(() => import("@/pages/battles/BattlePage"));
const WalletPage = lazy(() => import("@/pages/wallet/WalletPage"));
const ProfilePage = lazy(() => import("@/pages/profile/ProfilePage"));
const PublicProfilePage = lazy(
  () => import("@/pages/profile/PublicProfilePage")
);
const ErrorPage = lazy(() => import("@/pages/error"));
const LeaderboardPage = lazy(() => import("@/pages/leaderboard/index"));
const SplashPage = lazy(() => import("@/pages/splash/SplashPage"));

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "battles", element: <BattlesPage /> },
      { path: "battles/:id", element: <BattlePage /> },
      { path: "hype-boards", element: <HypeBoardsPage /> },
      { path: "hype-boards/:id", element: <HypeBoardPage /> },
      { path: "leaderboard", element: <LeaderboardPage /> },
      {
        path: "wallet",
        element: (
          <ProtectedRoute>
            <WalletPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        ),
      },
      { path: "/profile/:walletAddress", element: <PublicProfilePage /> },
      { path: "*", element: <ErrorPage message="Not found" /> },
    ],
  },
  { path: "/splash", element: <SplashPage /> },
];
