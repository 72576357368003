import { Card, CardContent } from "@/components/ui/card";
import { RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import React from "react";

interface Props {
  type: "Week A" | "Week B";
  title?: string;
  subtitle?: string;
  isSelected: boolean;
}

const WeekOption: React.FC<Props> = ({
  title = "",
  subtitle = "",
  type,
  isSelected,
}) => {
  return (
    <Label htmlFor={type} className="flex-1 cursor-pointer">
      <Card
        className={`mb-2 flex-1 rounded-xl ${isSelected ? "border-[1px] border-green-400 bg-slate-700" : "bg-primary-foreground"}`}>
        <CardContent className="p-4">
          <div className="flex h-full flex-1 space-x-5">
            <div className="flex items-center">
              <RadioGroupItem
                value={type}
                id={type}
                className={`h-4 w-4 ${isSelected ? "border-green-400 bg-secondary text-green-400" : "border-2 border-input"}`}
              />
            </div>
            <div className="flex w-full flex-1 flex-col items-start justify-center space-y-2">
              <h3 className="text-lg font-medium text-foreground">{title}</h3>
              <h3 className="text-sm font-medium text-foreground text-slate-300">
                {subtitle}
              </h3>
            </div>
          </div>
        </CardContent>
      </Card>
    </Label>
  );
};

export default WeekOption;
