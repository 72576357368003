import React from "react";
import { HypeboardArtist } from "@dripit/types";

import { cn } from "@/helpers/tailwind";
import { getHypeColor } from "@/helpers";
import { Separator } from "@/components/ui";
import { HypeMeter } from "./HypeMeter";
import isEmpty from "lodash.isempty";

interface HypeLeaderboardItemProps {
  rank: number;
  artist: HypeboardArtist;
  hypeMeter: number;
  onClick?: (id: number) => void;
}

export const HypeLeaderboardItem: React.FC<HypeLeaderboardItemProps> = ({
  rank,
  artist,
  hypeMeter,
  onClick,
}) => (
  <button
    className={cn(
      "flex flex-col justify-between gap-3 md:flex-row",
      "rounded-md bg-slate-800 p-2 transition-colors hover:bg-slate-700"
    )}
    onClick={() => onClick?.(artist.id)}>
    <div className="flex items-center gap-3 md:gap-6">
      <div
        className={cn(
          "flex size-5 shrink-0 items-center justify-center rounded-full border border-slate-500"
        )}>
        <span className="font-phonk text-[8px] text-slate-500">{rank}</span>
      </div>
      {/* artist info */}
      <div className="flex items-center gap-3 md:gap-5">
        {artist.image ? (
          <img
            src={artist.image}
            alt={artist.name}
            className="size-9 rounded-sm"
          />
        ) : (
          <div className="size-9 rounded-sm bg-slate-500/20" />
        )}
        <div className="max-w-[200px] text-left">
          <p className="truncate font-geist text-sm">{artist.name}</p>
          <p
            className={cn("text-xs text-slate-500", {
              "opacity-0": isEmpty(artist.country),
            })}>
            {artist?.country ?? "-"}
          </p>
        </div>
      </div>
    </div>
    <Separator className="my-1 h-0 border-t border-dashed border-slate-600 md:hidden" />
    {/* hype meter info */}
    <div className="flex shrink-0 flex-grow items-center gap-3 md:max-w-56">
      <HypeMeter percentage={hypeMeter} />
      <div
        className={cn(
          "flex h-min items-center justify-center gap-1 font-geist text-xs text-slate-900",
          "w-[72px] rounded-full px-2 py-1 md:w-10 md:rounded-md"
        )}
        style={{
          backgroundColor: getHypeColor(Math.floor(hypeMeter ?? 0)),
        }}>
        <span className="font-bold">{hypeMeter?.toFixed(0)}%</span>
        <span className="md:hidden">Hype</span>
      </div>
    </div>
  </button>
);
