import React, { useEffect, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";
import { Card, CardContent } from "@/components/ui/card";
import BetPlacement from "./BetPlacement";
import VS from "@/assets/svg/vs-battle.svg?react";
import { getCurrentAndNextWeeks } from "@/helpers";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";

const StepPredict: React.FC = () => {
  const { watch, control } = useFormContext();
  const formData = watch();
  const { selectedItems, challengeType, week } = formData;
  const weeks = getCurrentAndNextWeeks();
  const selectedWeek = week === "Week A" ? weeks.currentWeek : weeks.nextWeek;
  const [amountA, setAmountA] = useState(
    formData?.bet?.side === 1 ? formData?.bet?.amount : ""
  );
  const [amountB, setAmountB] = useState(
    formData?.bet?.side === 2 ? formData?.bet?.amount : ""
  );

  return (
    <FormField
      control={control}
      name="bet"
      defaultValue={{ side: null, amount: 0 }}
      render={() => (
        <FormItem className="mt-7">
          <FormControl>
            <div className="flex flex-col space-y-3">
              <div className="flex justify-end">
                <span className="pr-1 font-geist text-[0.7rem] text-indigo-300">
                  <span>Minimum:</span>
                  <span className="ml-1.5 font-bold">$ 10</span>
                </span>
              </div>
              <Card className="w-full rounded-xl bg-primary-foreground">
                <CardContent className="flex flex-col items-center justify-between gap-8 p-4 md:flex-row md:gap-2">
                  <BetPlacement
                    title={selectedItems?.sideA?.name}
                    subtitle={`${challengeType} A`}
                    image={selectedItems.sideA.images[0].url}
                    isSideA={true}
                    amount={amountA}
                    setAmount={(e) => {
                      setAmountB("");
                      setAmountA(e);
                    }}
                  />
                  <span className="text-lg text-muted-foreground">
                    <VS />
                  </span>
                  <BetPlacement
                    title={selectedItems.sideB.name}
                    subtitle={`${challengeType} B`}
                    image={selectedItems.sideB.images[0].url}
                    isSideA={false}
                    amount={amountB}
                    setAmount={(e) => {
                      setAmountA("");
                      setAmountB(e);
                    }}
                  />
                </CardContent>
              </Card>
              <Card className="mt-7 w-full rounded-xl bg-primary-foreground">
                <CardContent className="flex flex-col gap-0 p-4 md:gap-2">
                  <div className="flex flex-col gap-2 md:flex-row md:justify-between">
                    <span
                      className={`grey-info mb-2 inline-block self-start rounded-sm p-[2px] px-1 text-[10px] font-light md:order-2 md:mb-0 md:p-1 md:text-sm`}>
                      Weekly Listeners
                    </span>
                    <h5 className="text-md font-geist text-white sm:text-lg">
                      {`${selectedItems.sideA.name}`}
                      &nbsp;
                      <strong className="text-xs font-black md:text-sm">
                        VS
                      </strong>
                      &nbsp;
                      {`${selectedItems.sideB.name}`}
                    </h5>
                  </div>
                  <p className="mt-3 font-geist text-sm text-slate-300">
                    Who will have more listeners in week{" "}
                    {`${selectedWeek.week}`} (
                    {`${selectedWeek.start} - ${selectedWeek.end}`}) ?
                  </p>
                </CardContent>
              </Card>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default StepPredict;
