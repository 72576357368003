export enum UrlParams {
  TICKET_QR = "ticketQr",
  REF_CODE = "refcode",
  BATTLE_TYPE = "battleType",
  PROFILE_TAB = "profileTab",
  DEPOSIT = "deposit",
  CREATE_BATTLE = "createBattle",
  HYPER_METER_TYPE = "hyperMeterType",
  SORTING_METHOD = "sortingMethod",
}
