import React, { useState } from "react";
import { resolveImage } from "@/helpers";
import { Input } from "@/components/ui";
import { DollarSignIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";

interface Props {
  title: string;
  subtitle: string;
  image: string;
  isSideA: boolean;
  amount: number | "";
  setAmount: (amount: number | "") => void;
}

const BetPlacement: React.FC<Props> = ({
  title,
  subtitle,
  image,
  isSideA,
  amount,
  setAmount,
}) => {
  const { setValue } = useFormContext();
  const side = isSideA ? 1 : 2;
  const handleBetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    setAmount(newValue === 0 ? "" : newValue);
    setValue("bet", {
      side: newValue === 0 ? null : side,
      amount: newValue === 0 ? "" : newValue,
    });
  };
  return (
    <div className="flex w-full space-x-6 md:w-auto">
      <img
        className="h-24 w-24 rounded-md md:h-20 md:w-20"
        src={resolveImage(image, "400x400")}
        alt={title}
      />
      <div className="flex w-full flex-col items-start justify-between gap-2 md:w-auto">
        <div className="flex flex-col gap-2">
          <h5 className="h-5 font-geist text-lg xl:text-lg 2xl:text-[1.25rem]">
            {title}
          </h5>
          <p className="font-geist text-xs text-slate-400">{subtitle}</p>
        </div>
        <div className="relative inline-block w-full md:w-auto">
          <DollarSignIcon className="absolute left-2 top-1/2 h-3 w-3 -translate-y-1/2 transform" />
          <Input
            type="number"
            className="h-10 w-full flex-none rounded-md border-slate-400 bg-transparent pl-6 text-sm text-white placeholder:text-slate-400 focus-visible:outline-none md:h-7 md:w-32"
            value={amount}
            min={1}
            max={1000}
            placeholder="0"
            onChange={handleBetChange}
            onKeyDown={(e) =>
              (e.key === "." || e.key === ",") && e.preventDefault()
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BetPlacement;
