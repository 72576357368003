import { useQuery } from "@tanstack/react-query";
import {
  GetHypeboardByIdApiResponse,
  GetHypeboardsApiResponse,
  GetHypeboardBattlesApiResponse,
} from "@dripit/types";

import isNil from "lodash.isnil";
import { client } from "@/api/base";
import { queryKeys } from "@/api/constants";

export const useHypeboards = () =>
  useQuery<GetHypeboardsApiResponse>({
    queryKey: [queryKeys.HYPEBOARDS],
    queryFn: async () =>
      await client.clearParams().get<GetHypeboardsApiResponse>("hypeboards"),
  });

export const useHypeboard = (id?: string) =>
  useQuery<GetHypeboardByIdApiResponse>({
    queryKey: [queryKeys.HYPEBOARD, id],
    queryFn: () =>
      client.clearParams().get<GetHypeboardByIdApiResponse>(`hypeboards/${id}`),
    staleTime: 10_000,
    enabled: !isNil(id),
  });

export const useHypeboardBattles = (id?: string) =>
  useQuery<GetHypeboardBattlesApiResponse>({
    queryKey: [queryKeys.HYPEBOARD_BATTLES, id],
    queryFn: () =>
      client
        .clearParams()
        .get<GetHypeboardBattlesApiResponse>(`hypeboards/${id}/battles`),
    staleTime: 10_000,
    enabled: !isNil(id),
  });
