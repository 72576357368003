import React from "react";
import { Battle } from "@dripit/types";

import { Button, ButtonProps } from "@/components/main";
import { cn } from "@/helpers/tailwind";
import {
  BanIcon,
  CheckIcon,
  HandCoinsIcon,
  HourglassIcon,
  TrophyIcon,
  XIcon,
} from "lucide-react";
import { BattleDetails, UserBattleStatus } from "@/types";
import isNil from "lodash.isnil";

interface CtaProps extends ButtonProps {
  minimal?: boolean;
  status: UserBattleStatus;
  position?: number;
  artistId: number;
  winnerId?: Battle["winner_id"];
  claims?: BattleDetails["claims"];
}

export const Cta: React.FC<CtaProps> = ({
  minimal,
  status,
  position,
  artistId,
  winnerId,
  ...props
}) => {
  const hasRewardToClaim = artistId === winnerId && !isNil(position);
  const hasClaimedReward = false;
  const hasRefundToClaim = false;
  const hasClaimedRefund = false;

  let content: React.ReactNode = "Buy";
  let classNames =
    "bg-indigo-400/15 text-indigo-400 hover:bg-indigo-600 hover:text-white";

  const getWinContent = () => {
    classNames = "bg-teal-400/15 text-teal-400 pointer-events-none";
    content = minimal ? (
      "Win"
    ) : (
      <>
        <CheckIcon className="size-4" />
        <span>Winner</span>
      </>
    );
  };

  // const hideCta = () => {
  //   classNames = "opacity-0 pointer-events-none"; // hide
  // };

  if (status === "pending") {
    if (minimal) {
      // classNames = "opacity-0 pointer-events-none"; // hide
    } else {
      classNames = "bg-orange-400/15 text-orange-400 text-[10px]";
      content = (
        <>
          <HourglassIcon className="!size-3" />
          <span>Waiting for results</span>
        </>
      );
    }
  }

  if (status === "won") {
    if (hasRewardToClaim) {
      classNames = "bg-teal-400 text-slate-900 hover:bg-teal-400/80";
      content = minimal ? (
        "Claim"
      ) : (
        <>
          <TrophyIcon className="size-4" />
          <span>Claim</span>
        </>
      );
    } else if (hasClaimedReward) {
      classNames = "bg-teal-400/15 text-teal-400 pointer-events-none";
      content = minimal ? (
        "Claimed"
      ) : (
        <>
          <CheckIcon className="size-4" />
          <span>Claimed</span>
        </>
      );
    } else if (artistId === winnerId) {
      getWinContent();
    } else {
      if (minimal) {
        // classNames = "opacity-0 pointer-events-none"; // hide
        return;
      } else {
        classNames = "bg-slate-700 text-slate-400 pointer-events-none";
        content = (
          <>
            <XIcon />
            <span>Defeated</span>
          </>
        );
      }
    }
  }

  if (status === "lost" || status === "defeated") {
    if (artistId === winnerId) {
      getWinContent();
    } else {
      if (minimal) {
        // classNames = "opacity-0 pointer-events-none"; // hide
        return;
      } else {
        classNames = "bg-slate-700 text-slate-400 pointer-events-none";
        content = (
          <>
            <XIcon />
            <span>Defeated</span>
          </>
        );
      }
    }
  }

  if (status === "canceled") {
    if (hasClaimedRefund) {
      classNames = "bg-teal-400/15 text-teal-400 pointer-events-none";
      content = minimal ? (
        "Claimed"
      ) : (
        <>
          <CheckIcon className="size-4" />
          <span>Claimed</span>
        </>
      );
    } else if (hasRefundToClaim) {
      classNames = "bg-orange-400 text-slate-900";
      content = minimal ? (
        "Refund"
      ) : (
        <>
          <HandCoinsIcon className="size-4" />
          <span>Refund</span>
        </>
      );
    } else {
      if (minimal) {
        // classNames = "opacity-0 pointer-events-none"; // hide
        return;
      } else {
        classNames = "bg-slate-700 text-slate-400 pointer-events-none";
        content = (
          <>
            <BanIcon />
            <span>Canceled</span>
          </>
        );
      }
    }
  }

  return (
    <Button
      size="sm"
      className={cn("h-min flex-shrink-0 px-2 py-1.5", classNames, {
        "w-full": !minimal,
      })}
      {...props}>
      {content}
    </Button>
  );
};
