import React from "react";
import { cn } from "@/helpers/tailwind";

interface VerticalScrollListProps {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  fadeClassName?: string;
  showFades?: boolean;
  showScrollbar?: boolean;
}

const VerticalScrollList: React.FC<VerticalScrollListProps> = ({
  children,
  className,
  containerClassName,
  fadeClassName = "from-background",
  showFades = false,
  showScrollbar = false,
}) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [topFadeOpacity, setTopFadeOpacity] = React.useState(0);
  const [bottomFadeOpacity, setBottomFadeOpacity] = React.useState(1);

  const handleScroll = React.useCallback(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const scrollHeight = container.scrollHeight;
    const containerHeight = container.clientHeight;
    const maxScroll = scrollHeight - containerHeight;
    const scrollTop = container.scrollTop;
    const scrollBottom = maxScroll - scrollTop;

    if (scrollTop >= 10) setTopFadeOpacity(1);
    else if (scrollTop < 10) setTopFadeOpacity(0);

    if (scrollBottom >= 10) setBottomFadeOpacity(1);
    else if (scrollBottom < 10) setBottomFadeOpacity(0);
  }, []);

  React.useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const fadeClassNames = cn(
    "h-8 w-full pointer-events-none absolute inset-x-0 to-transparent",
    "transition-opacity duration-300 ease-in-out z-[1]",
    { [fadeClassName]: showFades }
  );

  return (
    <div
      className={cn("relative flex-grow overflow-hidden", containerClassName)}>
      <div
        ref={scrollContainerRef}
        className={cn(
          "overflow-y-auto overflow-x-hidden",
          "scrollbar-thin flex h-full flex-col items-center gap-2",
          className,
          { "scrollbar-hide": !showScrollbar, "pr-3": showScrollbar }
        )}>
        {children}
      </div>
      {/* vertical fades */}
      {showFades && (
        <>
          <div
            className={cn("top-0 bg-gradient-to-b", fadeClassNames, {
              "opacity-100": topFadeOpacity === 1,
              "opacity-0": topFadeOpacity === 0,
            })}
          />
          <div
            className={cn("bottom-0 bg-gradient-to-t", fadeClassNames, {
              "opacity-100": bottomFadeOpacity === 1,
              "opacity-0": bottomFadeOpacity === 0,
            })}
          />
        </>
      )}
    </div>
  );
};

export default VerticalScrollList;
