import React from "react";
import isNil from "lodash.isnil";

import { cn } from "@/helpers/tailwind";
import { Separator } from "@/components/ui";

interface PageWrapperProps {
  children?: React.ReactNode;
  leftSide?: React.ReactNode;
  rightSide?: React.ReactNode;
  separator?: "simple" | "always";
  hideBottomFade?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children = null,
  leftSide = null,
  rightSide = null,
  separator = "simple",
  hideBottomFade = false,
}) => {
  const colClasses = "px-6 sm:px-8 md:px-10 pb-6";

  const bottomFade = (
    <>
      <div className="hidden h-10 bg-transparent md:block" />
      <div
        className={cn(
          "bottom-[-2px] z-30 hidden md:block",
          "pointer-events-none absolute h-16 w-[-webkit-fill-available]",
          "bg-[linear-gradient(to_top,hsl(var(--background))_0%,hsl(var(--background))_22.5%,transparent_100%)]"
        )}
      />
    </>
  );

  if (isNil(leftSide) && isNil(rightSide)) {
    return (
      <div className="mx-auto w-full max-w-6xl px-6 pb-4 pt-2 lg:pt-6">
        <>{children}</>
        {!hideBottomFade && bottomFade}
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col pt-2 md:h-full lg:pt-6 xl:flex-row">
        <div className={cn("flex justify-center xl:flex-grow", colClasses)}>
          {leftSide}
        </div>
        {separator === "simple" && (
          <Separator
            orientation="vertical"
            className="z-[31] mt-[-50px] hidden h-auto xl:block"
          />
        )}
        {separator === "always" && (
          <>
            <div className="relative mt-[-50px] hidden h-auto xl:block">
              <Separator
                orientation="vertical"
                className="h-100vh fixed top-0 z-[31]"
              />
            </div>
            <div className={cn("xl:hidden", colClasses)}>
              <Separator className="z-[31] mb-4 mt-6 border-t border-dashed border-slate-600" />
            </div>
          </>
        )}
        <div
          className={cn(
            "flex w-full flex-col items-center xl:w-5/12 xl:items-stretch",
            colClasses
          )}>
          {rightSide}
        </div>
      </div>
      {!hideBottomFade && bottomFade}
    </div>
  );
};

export default PageWrapper;
