import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import FullLogoIcon from "@/assets/svg/fullLogo.svg?react";
import { cn } from "@/helpers/tailwind";
import { useAuth } from "@/hooks";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const MINIMUM_LOADING_TIME = 750;

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();
  const { isAuthenticated, isInitialized } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  React.useEffect(() => {
    if (isInitialized) {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setIsFirstLoad(false);
      }, MINIMUM_LOADING_TIME);
      return () => clearTimeout(timer);
    }
  }, [isInitialized]);

  return (
    <>
      <div
        className={cn(
          "absolute left-0 top-0 z-50 flex h-screen w-full items-center justify-center bg-background opacity-100",
          "transition-all duration-300 ease-in-out",
          { "pointer-events-none opacity-0": isInitialized && !isLoading }
        )}>
        <FullLogoIcon className="animate-float" />
      </div>
      {isInitialized && !isLoading && (
        <>
          {!isAuthenticated ? (
            <Navigate to={`/battles${location.search}`} replace />
          ) : (
            <div className={cn({ "animate-fade-in": isFirstLoad })}>
              {children}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProtectedRoute;
