import { HypeboardArtist } from "@dripit/types";

export const HYPE_BOARDS = [
  { value: 1, label: "Global Artists" },
  { value: 2, label: "Greek Rap Artists" },
  { value: 3, label: "European Metal Artists" },
  { value: 4, label: "Hip-Hop Artists" },
  { value: 5, label: "Pop Artists" },
  { value: 6, label: "Latin Music Artists" },
  { value: 7, label: "Rock Artists" },
  { value: 8, label: "R&B/Soul Artists" },
  { value: 9, label: "Metal Bands" },
  { value: 10, label: "Indie Artists" },
  { value: 11, label: "K-Pop Artists" },
  { value: 12, label: "Jazz Artists" },
];

const countries = [
  "USA",
  "Colombia",
  "Greece",
  "Germany",
  "China",
  "Japan",
  "Chech Republic",
];

const getRandomData = () => ({
  country:
    Math.random() < 0.5
      ? undefined
      : countries[Math.floor(Math.random() * countries.length)],
  rank: Math.floor(Math.random() * 100),
  image: "https://i.scdn.co/image/ab6761610000e5ebca9ec71491fa790547df4d62",
  timeframes: [
    { timeframe: 7, score: Number((Math.random() * 100).toFixed(3)) },
    { timeframe: 30, score: Number((Math.random() * 100).toFixed(3)) },
    { timeframe: 365, score: Number((Math.random() * 100).toFixed(3)) },
  ],
});

export const mockArtists: HypeboardArtist[] = [
  {
    id: 1,
    name: "Drake",
    ...getRandomData(),
    image: "https://i.scdn.co/image/ab6761610000e5ebca9ec71491fa790547df4d62",
  },
  {
    id: 2,
    name: "The Weeknd",
    ...getRandomData(),
    image: "https://i.scdn.co/image/ab6761610000e5eb73a9856e0b1918e5254f0ad0",
  },
  {
    id: 3,
    name: "Lil Baby",
    ...getRandomData(),
    image: "https://i.scdn.co/image/ab6761610000e5eb73a9856e0b1918e5254f0ad0",
  },
  {
    id: 4,
    name: "Post Malone",
    ...getRandomData(),
  },
  { id: 5, name: "Travis Scott", ...getRandomData() },
  { id: 6, name: "Franz Liszt", ...getRandomData() },
  { id: 7, name: "Eminem", ...getRandomData() },
  { id: 8, name: "Kendrick Lamar", ...getRandomData() },
  {
    id: 9,
    name: "Red Hot Chili Peppers & their brothers too",
    ...getRandomData(),
  },
  { id: 10, name: "Ed Sheeran", ...getRandomData() },
  { id: 11, name: "Taylor Swift", ...getRandomData() },
  { id: 12, name: "The Beatles", ...getRandomData() },
  { id: 13, name: "The Rolling Stones", ...getRandomData() },
  { id: 14, name: "The Eagles", ...getRandomData() },
  { id: 15, name: "The Beach Boys", ...getRandomData() },
  { id: 16, name: "The Beatles", ...getRandomData() },
  { id: 17, name: "The Rolling Stones", ...getRandomData() },
  { id: 18, name: "The Eagles", ...getRandomData() },
];

export const hypeBoardCards = HYPE_BOARDS.map(({ value, label }) => ({
  id: value,
  title: label,
  artists: mockArtists,
}));
