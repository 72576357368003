import React, { useState } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { X } from "lucide-react";

import { Button, Card, CardContent, Input } from "@/components/ui";
import { InstanceItem } from "@/types";
import {
  getAccessToken,
  getSpotifyToken,
  searchItems,
  searchSpotify,
} from "@/api/spotify/requests";
import { fetchImage, resolveImage } from "@/helpers";

interface Props {
  isSideA?: boolean;
}

const SpotifySearch: React.FC<Props> = ({ isSideA = false }) => {
  const side = isSideA ? "sideA" : "sideB";
  const { watch, setValue } = useFormContext();
  const selectedItems = watch("selectedItems");
  const [query, setQuery] = useState(selectedItems?.[side]?.name || "");
  const [debouncedQuery, setDebouncedQuery] = useState(query);
  const [isDisabled, setIsDisabled] = useState(Boolean(query.length));
  const challengeType = watch("challengeType");

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(query);
    }, 250);

    return () => clearTimeout(timer);
  }, [query]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const {
    data: token,
    isLoading: isTokenLoading,
    error: tokenError,
  } = useQuery({
    queryKey: ["spotifyToken"],
    queryFn: getAccessToken,
    staleTime: 3540000, // 59 minutes
  });

  const {
    data: searchResults = [] as InstanceItem[],
    isLoading: isSearchLoading,
    error: searchError,
  } = useQuery({
    queryKey: ["spotifySearch", debouncedQuery, token, challengeType],
    queryFn: ({ queryKey }) =>
      searchItems(queryKey[1], queryKey[2], queryKey[3]),
    enabled: !!token && debouncedQuery.length > 0 && !!challengeType,
    staleTime: 300000, // 5 minutes
  });

  // Fetch and cache images
  const imageQueries = useQueries({
    queries: searchResults.map((item: InstanceItem) => {
      const instanceImages =
        "album" in item ? item.album?.images?.[0]?.url : item.images?.[0]?.url;
      const config = {
        queryKey: ["image", instanceImages],
        queryFn: () => fetchImage(instanceImages),
        enabled: !!instanceImages,
        staleTime: Infinity,
        cacheTime: Infinity,
      };

      return config;
    }),
  });

  const addItem = (item: InstanceItem) => {
    const clonedItems = { ...selectedItems };
    clonedItems[side] = item;
    setValue("selectedItems", { ...clonedItems });
    setQuery(item.name);
  };

  const handleSelect = (item: InstanceItem) => {
    addItem(item);
    setIsDisabled(true);
  };

  const handleDelete = () => {
    const clonedItems = { ...selectedItems };
    clonedItems[side] = null;
    setValue("selectedItems", { ...clonedItems });
    setQuery("");
    setIsDisabled(false);
  };

  const article = `a${challengeType === "Artist" ? "n" : ""}`;

  return (
    <div className="space-y-4">
      <div className="relative">
        <Input
          type="text"
          placeholder={`Search for ${article} ${challengeType?.toLowerCase()}...`}
          value={query}
          onChange={handleInputChange}
          className="w-full border-slate-400 pr-10"
          disabled={isTokenLoading || isDisabled}
        />
        <Button
          onClick={handleDelete}
          disabled={query.length === 0}
          size="icon"
          variant="ghost"
          className="absolute inset-y-0 right-0 flex items-center hover:bg-transparent">
          <X className="h-3 w-3" />
        </Button>
      </div>
      {/* {isTokenLoading && <div>Fetching access token...</div>} */}
      {/* {isSearchLoading && (
        <div>Searching {challengeType.toLowerCase()}s...</div>
      )} */}
      {searchError && (
        <div className="text-red-500">Error: {searchError.message}</div>
      )}
      {searchResults.length > 0 && query && !isDisabled && (
        <Card className="absolute z-10 w-[68%] border-slate-400 bg-primary-foreground sm:w-[56%] md:w-[42%] lg:w-[56%]">
          <CardContent className="p-2">
            <ul>
              {searchResults.map((item: InstanceItem, index: number) => (
                <li
                  key={item.id}
                  className="flex cursor-pointer items-center p-2 hover:rounded-lg hover:bg-accent"
                  onClick={() => handleSelect(item)}>
                  <img
                    src={resolveImage(
                      (imageQueries?.[index]?.data as string) || null
                    )}
                    alt={item.name}
                    className="mr-3 h-10 w-10 rounded-lg"
                  />
                  <span>{item.name}</span>
                </li>
              ))}
            </ul>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SpotifySearch;
