import React from "react";
import { NavLink } from "react-router-dom";
import { ArrowLeftIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";

import FullLogo from "@/assets/svg/fullLogo.svg?react";
import ShortLogo from "@/assets/svg/shortLogo.svg?react";
import { Button, Card, Separator } from "@/components/ui";
import User from "@/components/layout/User";
import TokenBalance from "@/components/layout/TokenBalance";
import { useTheme } from "@/contexts/Theme";
import { desktopNavItems } from "@/constants";
import { useSidebar } from "@/contexts/SidebarContext";
import { useBreakpoints, useTranslate } from "@/hooks";
import { cn } from "@/helpers/tailwind";

const Sidebar = () => {
  const t = useTranslate();
  const { theme } = useTheme();
  const { isExpanded, toggleSidebar } = useSidebar();
  const { isGreaterThan } = useBreakpoints();
  const isDark = React.useMemo(() => theme === "dark", [theme]);
  const isLoggedIn = useIsLoggedIn();

  React.useEffect(() => {
    if (!isGreaterThan("lg")) {
      toggleSidebar(false);
    }
  }, [isGreaterThan]);

  return (
    <Card className="relative hidden h-full w-full flex-col items-center rounded-2xl bg-background px-4 py-8 transition-all duration-1000 md:flex">
      <NavLink
        to={"/"}
        className={cn("relative mb-2 w-full py-3", {
          "flex justify-center": !isExpanded,
        })}>
        <div
          className={cn(
            "absolute inset-0 flex items-center pl-2 text-lightblue transition-all duration-300",
            {
              "scale-75 opacity-0": !isExpanded,
              "scale-100 opacity-100": isExpanded,
            }
          )}>
          <FullLogo />
        </div>
        <div
          className={cn(
            "absolute inset-0 flex items-center justify-center text-lightblue transition-all duration-300",
            {
              "scale-75 opacity-0": isExpanded,
              "scale-100 opacity-100": !isExpanded,
            }
          )}>
          <ShortLogo />
        </div>
      </NavLink>
      <Button
        onClick={() => toggleSidebar()}
        className="absolute -right-4 top-8 hidden h-7 w-7 rounded-full bg-slate-700 p-0 text-white hover:bg-slate-800 lg:flex">
        {isExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
      </Button>
      <TokenBalance />
      <Separator className="my-6" />
      <div className="flex h-full w-full flex-col justify-between overflow-hidden">
        <div
          className={cn("mt-4 flex flex-col gap-1", {
            "items-center": !isExpanded,
          })}>
          {desktopNavItems.map((item) =>
            item.authProtected && !isLoggedIn ? null : (
              <NavLink key={item.path} to={item.path} className="w-full">
                {({ isActive }: { isActive: boolean }) => (
                  <Button
                    className={cn("h-14 w-full [&_svg]:size-5", {
                      "bg-slate-700": isActive && isDark,
                      "bg-slate-200": isActive && !isDark,
                      "bg-transparent": !isActive,
                      "text-white hover:bg-slate-600": isDark,
                      "text-slate-700 hover:bg-slate-100": !isDark,
                      "w-full justify-start": isExpanded,
                    })}>
                    <div className={cn({ "w-1/5": isExpanded })}>
                      {item.icon}
                    </div>
                    {isExpanded && (
                      <span className="w-4/5 pl-2 text-start text-sm font-normal xl:text-lg">
                        {t(item.namePath)}
                      </span>
                    )}
                  </Button>
                )}
              </NavLink>
            )
          )}
        </div>
        <div className="w-full">
          {/* Removing based on BATTLES-161 - to bring it back after launch. */}
          {/* <ThemeToggle /> */}
          <Separator className="mt-4" />
          <User />
        </div>
      </div>
    </Card>
  );
};

export default Sidebar;
