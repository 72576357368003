import { ClaimType } from "@dripit/constants";
import { BattlesType } from "@dripit/types";

export enum InstanceType {
  Artist = "Artist",
  Track = "Track",
  Album = "Album",
}

interface ItemImage {
  height: 640;
  url?: string;
  width: 640;
}

interface GenericBattleInstance {
  id: string;
  name: string;
  href: string;
  images: ItemImage[];
  external_urls: {
    spotify?: string;
  };
  type:
    | Lowercase<InstanceType.Artist>
    | Lowercase<InstanceType.Album>
    | Lowercase<InstanceType.Track>;
  uri: string;
}

interface Market {
  available_markets: string[];
  popularity?: number;
}

interface Album extends GenericBattleInstance, Market {
  album_type: string;
  artists: Artist[];
  available_markets: string[];
  release_date: string;
  release_date_precision: string;
  total_tracks: number;
}

export interface Artist extends GenericBattleInstance {
  followers?: {
    href: string | null;
    total: number;
  };
  genres: string[];
  popularity?: number;
}

export interface Track extends GenericBattleInstance, Market {
  album: Album;
  artists: Artist[];
  available_markets: string[];
  disc_number: number;
  duration_ms: number;
  explicit: false;
  external_ids: {
    isrc: string;
  };
  is_local: false;
  popularity?: number;
}

export type InstanceItem = Track | Artist;

export interface BattleItem {
  challengeType: InstanceType.Artist | InstanceType.Track;
  selectedItems: InstanceItem[];
}

export interface ArtistInfoItem {
  id: number;
  bio: string | null;
  country?: string;
  image: string;
  name: string;
  slug: string;
  spotify_id: string;
  wins_count?: number;
  updated_at?: string;
  losses_count?: number;
  hypeMeter?: number;
  percentage?: number;
}

export interface BattleClaim {
  id: number;
  type: ClaimType;
  amount: string;
  claimed_at: string | null;
  position_id: number;
  battle_id: number;
  user_id: number;
  battle?: { id: number; onchainId: number };
}

interface BattlePosition {
  id: number;
  amount: string;
  battle_id: number;
  claims: BattleClaim[];
  created_at: string;
  user_id: number;
  won: boolean;
  side: boolean;
}

export interface Battle {
  id: number;
  onchainId: number;
  type: BattlesType;
  // TODO - Will be implemented later
  // type:
  //   | Lowercase<InstanceType.Artist>
  //   | Lowercase<InstanceType.Album>
  //   | Lowercase<InstanceType.Track>;
  status: number;
  sideA_id: number;
  sideB_id: number;
  amountA: string;
  amountB: string;
  imageA: string;
  imageB: string;
  total: string;
  start_date: string;
  end_date: string;
  created_by: number;
  sideA: ArtistInfoItem;
  sideB: ArtistInfoItem;
  positions: BattlePosition[];
  winner_id: number | null;
  claims?: BattleClaim[];
}

export interface CommentUser {
  avatar: string | null;
  username: string;
}

export interface BattleComment {
  id: number;
  battle_id: number;
  user_id: number;
  user: CommentUser;
  text: string;
  reactions?: number;
  created_at: string;
  replies_to: null | number;
}

interface CreatorInfo {
  ambassador: boolean;
  avatar: string;
  username: string;
}

export interface BattleDetails {
  id: number;
  name: string;
  type: BattlesType;
  onchainId: number;
  status: number;
  sideA_id: number;
  sideB_id: number;
  amountA: string;
  amountB: string;
  imageA: string;
  imageB: string;
  total: string;
  start_date: string;
  end_date: string;
  created_by: number;
  creatorInfo: CreatorInfo;
  winner_id: number | null;
  artistsInfo: ArtistInfoItem[];
  comments: BattleComment[];
  position?: BattlePosition;
  claims?: BattleClaim[];
}

export interface ArtistCellData {
  id: number;
  amount: string;
  image: string;
  name: string;
  orientation: "left" | "right";
}

export interface ArtistRowData {
  artist: ArtistCellData;
}

export interface Ticket {
  tokenId: number;
  user_id: number;
  issuer: string;
  amount: string;
  expiration_date: string;
  minted_at: string; // date
  used: boolean;
}

export interface PagedDataResponse<T> {
  results: T[];
  total_items: number;
  current_page: number;
  limit: number;
  total_pages: number;
}

export interface DateDiff {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface CommentPayload {
  comment: string;
  replies_to?: number;
}

export enum BattleStatus {
  Open = "open", // before start date
  Pending = "pending", // after start date & before end date
  Completed = "completed", // after end date
  Canceled = "canceled",
}

export enum UserBattleStatus {
  Active = "active",
  Won = "won",
  Lost = "lost",
  Pending = "pending",
  Canceled = "canceled",
  Defeated = "defeated",
}
