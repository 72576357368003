import React from "react";
import { Timeframe } from "@dripit/types";

import { HypeLeaderboardItem } from "./HypeLeaderboardItem";
import { cn } from "@/helpers/tailwind";
import { Skeleton } from "@/components/ui";
import { RankedHypeboardArtist } from "@/helpers";

interface HypeLeaderboardProps {
  className?: string;
  timeframe: Timeframe["timeFrame"];
  artists: RankedHypeboardArtist[];
  loading?: boolean;
}

export const HypeLeaderboard: React.FC<HypeLeaderboardProps> = ({
  className,
  artists,
  timeframe,
  loading = true,
}) => {
  const sortedArtists = React.useMemo(
    () =>
      [...artists].sort(
        (a, b) =>
          (a.timeFrames?.find((tf) => tf.timeFrame === timeframe)?.rank ?? 0) -
          (b.timeFrames?.find((tf) => tf.timeFrame === timeframe)?.rank ?? 0)
      ),
    [artists, timeframe]
  );

  return (
    <div className={cn("flex flex-col gap-3", className)}>
      {loading
        ? Array.from({ length: 10 }).map((_, index) => (
            <Skeleton key={index} className="h-12 w-full bg-slate-200/20" />
          ))
        : sortedArtists.map((artist) => (
            <HypeLeaderboardItem
              key={artist.id}
              rank={
                artist.timeFrames?.find((tf) => tf.timeFrame === timeframe)
                  ?.rank ?? 0
              }
              artist={artist}
              hypeMeter={
                artist.timeFrames?.find((tf) => tf.timeFrame === timeframe)
                  ?.score ?? 0
              }
            />
          ))}
    </div>
  );
};
