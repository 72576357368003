import { cn } from "@/helpers/tailwind";
import React, { Component, ReactNode } from "react";
import tailwindConfig from "../../../../tailwind.config";

type GridTemplateColumns =
  keyof typeof tailwindConfig.theme.extend.gridTemplateColumns;

type Variant = "sm" | "md" | "lg";

type TemplateColumnsReference = {
  [key in Variant]: GridTemplateColumns;
};

type Gap = "regular";

const presetMap = {
  default: {
    sm: "2",
    md: "8",
    lg: "12",
  },
};

export type Preset = keyof typeof presetMap;

export interface GridProps extends React.HTMLAttributes<Component> {
  className?: string;
  gridTemplateColumns?: TemplateColumnsReference | GridTemplateColumns;
  component?:
    | React.FunctionComponent<ComponentProps>
    | keyof JSX.IntrinsicElements;
  preset?: Preset;
  gap?: Gap;
}

export interface ComponentProps {
  className?: string;
  children?: ReactNode;
}

const gapClassNameMap = {
  regular: "sm:gap-x-6 md:gap-x-10 lg:gap-x-10",
};

/**
 * Generates class names - define in safelist if tailwind config changes
 * @param {TemplateColumnsReference} gridTemplateColumns?
 * @return {string|string[]}
 */
const gridTemplateColumnsClassNames = (
  preset: Preset = "default",
  gridTemplateColumns?: TemplateColumnsReference | GridTemplateColumns
) => {
  const columns = gridTemplateColumns ? gridTemplateColumns : presetMap[preset];

  if (typeof columns == "object")
    return [
      `grid-cols-2`,
      `sm:grid-cols-${columns.sm}`,
      `md:grid-cols-${columns.md}`,
      `lg:grid-cols-${columns.lg}`,
    ];

  return gridTemplateColumns;
};

const DefaultComponent: React.FC<ComponentProps> = ({
  children,
  className,
}) => <div className={className}>{children}</div>;

/**
 * Renders a grid
 * @param {React.ReactNode} children
 * @param {TemplateColumnsReference} gridTemplateColumns?
 * @param {string} className?
 * @returns the grid, best to use Cell components as children
 */
export const Grid = ({
  children,
  gridTemplateColumns,
  preset,
  className,
  component,
  gap = "regular",
}: GridProps) => {
  const Component = component || DefaultComponent;
  return (
    <Component
      className={cn(
        "grid",
        "transition-all duration-300 ease-in-out",
        gap && gapClassNameMap[gap],
        gridTemplateColumnsClassNames(preset, gridTemplateColumns),
        className
      )}>
      {children}
    </Component>
  );
};

export default Grid;
