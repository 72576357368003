import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PlusIcon, XIcon, ArrowRight, Check } from "lucide-react";
import { useForm, FormProvider } from "react-hook-form";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

import { Button } from "@/components/main";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  Separator,
  useToast,
} from "@/components/ui";
import Grid from "../Grid/Grid";
import Cell from "../Grid/Cell";
import { useBreakpoints, useTranslate, useWeb3Query } from "@/hooks";
import StepperIndicator from "./StepperIndicator";
import StepBattleOptions from "./StepBattleOptions";
import StepPredict from "./StepPredict";
import { ContractFunc } from "@/types";
import { cn } from "@/helpers/tailwind";
import { UrlParams } from "@/constants";

const CreateBattleButton: React.FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isGreaterThan } = useBreakpoints();
  const { toast } = useToast();
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const methods = useForm<any>({
    mode: "onTouched",
    defaultValues: { challengeType: "Artist" },
  });
  const {
    handleSubmit,
    setError,
    watch,
    formState: { isSubmitting, errors },
    reset,
  } = methods;
  const formData = watch();
  const closeRef = React.useRef<HTMLButtonElement>(null);
  const [createBattle, { loading }] = useWeb3Query(ContractFunc.CREATE_BATTLE, {
    onSuccess: () => {
      closeRef.current?.click();
      toast({ title: "Success", description: "Successfully created battle!" });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Something went wrong while creating a battle.",
        variant: "destructive",
      });
    },
  });

  React.useEffect(() => {
    const createBattleParam = searchParams.get(UrlParams.CREATE_BATTLE);
    if (createBattleParam === "true") {
      searchParams.delete(UrlParams.CREATE_BATTLE);
      setOpen(true);
      navigate(`/battles?${searchParams.toString()}`, { replace: true });
    }
  }, [searchParams]);

  const getActiveComponent = (step: number) => {
    switch (step) {
      case 1:
        return <StepBattleOptions />;
      case 2:
        return <StepPredict />;
      default:
        return null;
    }
  };

  const onSubmit = async (): Promise<void> => {
    try {
      const payload = {
        manifest: {
          battleType: formData.challengeType === "Artist" ? 0 : 1,
          option0Id: formData.selectedItems.sideA.id,
          option1Id: formData.selectedItems.sideB.id,
        },
        option: formData.bet.side,
        amount: formData.bet.amount,
        useNextWindow: formData.week === "Week A",
      };
      await createBattle({ ...payload });
    } catch (error) {
      setError("root.formError", { message: (error as Error).message });
    }
  };

  const isDisabled = () => {
    if (activeStep === 1)
      return formData.selectedItems?.sideA &&
        formData.selectedItems?.sideB &&
        formData.week
        ? false
        : true;
    if (activeStep === 2) return (formData.bet?.amount ?? 0) < 10;
    return true;
  };

  return (
    <Drawer
      open={open}
      onOpenChange={setOpen}
      direction={isGreaterThan("md") ? "right" : "bottom"}>
      <DrawerTrigger asChild>
        <Button
          size="sm"
          className={cn(
            "bg-indigo-600 text-sm leading-6 text-white hover:bg-indigo-600/80",
            "rounded-full lg:rounded-lg"
          )}>
          <PlusIcon className="size-3" />
          <span>{t("battles.create")}</span>
        </Button>
      </DrawerTrigger>
      <DrawerContent
        overlayClassName="bg-slate-900/80"
        className={cn(
          "mt-0 w-full bg-slate-800 p-6 md:w-1/2 md:py-8 lg:p-10",
          "md:bottom-0 md:left-auto md:right-0 md:top-0 md:max-w-[600px] lg:max-w-[700px]",
          "rounded-tl-[30px] rounded-tr-[30px] md:rounded-bl-[30px] md:rounded-tr-[0px]"
        )}>
        <Grid className="h-full bg-transparent">
          <Cell start={{ sm: 1, md: 1, lg: 1 }} span={{ md: 12, lg: 12 }}>
            <div className="flex h-full w-full flex-col">
              <DrawerHeader className="relative mb-10 p-0">
                <div className="flex items-center justify-between gap-3">
                  <DrawerTitle className="flex-grow text-base">
                    {t("battle_weekly.create_battle")}
                  </DrawerTitle>
                  <div
                    className={cn(
                      "hidden lg:block",
                      "rounded-md bg-slate-500 px-1.5 py-0.5 text-xs text-white"
                    )}>
                    <p>Weekly Listeners</p>
                  </div>
                  <DrawerClose
                    ref={closeRef}
                    className="rounded-sm"
                    onClick={() => {
                      reset();
                      setActiveStep(1);
                    }}>
                    <XIcon className="size-5" />
                  </DrawerClose>
                </div>
                <div className="mb-3 mt-2 text-left text-xs text-slate-300">
                  <p>{t("battle_weekly.create_battle_subtitle")}</p>
                  <p>{t("battle_weekly.create_battle_description")}</p>
                </div>
                <Separator
                  orientation="horizontal"
                  className="my-0 hidden h-0 border-t border-dashed border-slate-700 lg:block"
                />
              </DrawerHeader>
              <div className="h-full pb-0">
                <StepperIndicator activeStep={activeStep} />
                {errors.root?.formError && (
                  <Alert variant="destructive" className="mt-[28px]">
                    <ExclamationTriangleIcon className="h-4 w-4" />
                    <AlertTitle>Form Error</AlertTitle>
                    <AlertDescription>
                      {errors.root?.formError?.message}
                    </AlertDescription>
                  </Alert>
                )}
                <div className="flex w-full">
                  <FormProvider {...methods}>
                    <form
                      className="flex w-full flex-col justify-center place-self-end"
                      noValidate>
                      <div>{getActiveComponent(activeStep)}</div>
                    </form>
                  </FormProvider>
                </div>

                <div className="mt-3 h-[120px]"></div>
              </div>
              <DrawerFooter className="flex w-full flex-row items-end justify-end p-0">
                {activeStep === 1 ? (
                  <DrawerClose asChild>
                    <Button
                      className="border border-slate-200 bg-slate-800 py-2"
                      variant="outline">
                      Cancel
                    </Button>
                  </DrawerClose>
                ) : (
                  <Button
                    className="border border-slate-200 bg-slate-800 py-2"
                    variant="outline"
                    disabled={loading}
                    onClick={() => setActiveStep(activeStep - 1)}>
                    Back
                  </Button>
                )}
                <Button
                  className="bg-indigo-600 py-2 text-white"
                  disabled={isSubmitting || isDisabled()}
                  loading={loading}
                  onClick={
                    activeStep === 2
                      ? handleSubmit(onSubmit)
                      : () => setActiveStep(activeStep + 1)
                  }>
                  {activeStep === 2 ? (
                    <>
                      <Check className="h-4 w-4" /> Create Battle
                    </>
                  ) : (
                    <>
                      Next <ArrowRight className="h-4 w-4" />
                    </>
                  )}
                </Button>
              </DrawerFooter>
            </div>
          </Cell>
        </Grid>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateBattleButton;
