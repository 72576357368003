import React from "react";
import { Check, Pencil } from "lucide-react";
import { cn } from "@/helpers/tailwind";

interface StepperIndicatorProps {
  activeStep: number;
}

const StepperIndicator: React.FC<StepperIndicatorProps> = ({ activeStep }) => {
  const steps = ["Battle Options", "Predict"];
  const mobileSteps = ["Options", "Predict"];

  return (
    <div className="flex items-center">
      {steps.map((label, index) => (
        <React.Fragment key={index}>
          <div
            className={cn(
              "flex items-center gap-1 rounded-full p-1",
              index + 1 < activeStep && "bg-primary text-primary-foreground",
              index + 1 === activeStep && "bg-primary text-primary-foreground",
              index + 1 > activeStep && "border border-slate-500"
            )}>
            <div
              className={cn(
                "flex h-7 w-7 items-center justify-center rounded-full border",
                index + 1 < activeStep
                  ? "border-green-300 bg-green-400 shadow-xl"
                  : index + 1 === activeStep
                    ? "border-[1px] border-indigo-400 bg-indigo-500"
                    : "border-gray-400 bg-slate-500"
              )}>
              {index + 1 < activeStep ? (
                <Check className="h-4 w-4 text-white" />
              ) : index + 1 === activeStep ? (
                <Pencil className="h-4 w-4 text-white" />
              ) : (
                <span className="text-sm font-medium">{index + 1}</span>
              )}
            </div>
            <span className="pr-1 text-sm font-medium">
              <span className="block md:hidden">{mobileSteps[index]}</span>
              <span className="hidden md:block">{label}</span>
            </span>
          </div>
          {index < steps.length - 1 &&
            (index + 1 < activeStep ? (
              <div
                className={cn("h-[2px] w-10", "bg-border dark:bg-slate-500")}
              />
            ) : (
              <DashedLine />
            ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StepperIndicator;

const DashedLine = () => {
  return (
    <svg className="" width={40} height="2">
      <line
        x1="0"
        y1="1"
        x2="100%"
        y2="1"
        stroke="#64748b"
        strokeWidth="2"
        strokeDasharray="2 4"
      />
    </svg>
  );
};
