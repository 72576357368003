import { Wallet } from "@dynamic-labs/sdk-react-core";
import { avalanche, avalancheFuji } from "viem/chains";
import { isProduction } from "../../helpers/environment";
import { USDC_ABI } from "@/constants/usdcABI";
import { usdToUsdc } from "../../helpers/convert";
import { encodeAbiParameters, Hex } from "viem";

interface GenPermitSigArgs {
  primaryWallet: Wallet | null;
  tokenAddress: string;
  walletAddress: `0x${string}`;
  spenderAddress: `0x${string}`;
  amount: number;
  deadline?: number;
}

export const generatePermitSignature = async ({
  primaryWallet,
  tokenAddress,
  walletAddress,
  spenderAddress,
  amount,
  deadline = Math.floor(Date.now() / 1000) + 3600, // 1 hour from now
}: GenPermitSigArgs): Promise<string> => {
  if (
    !primaryWallet ||
    !tokenAddress ||
    !walletAddress ||
    !spenderAddress ||
    !amount ||
    amount <= 0
  ) {
    console.log({
      mstatus: "Incorrect generatePermitSignature params",
      primaryWallet,
      tokenAddress,
      walletAddress,
      spenderAddress,
      amount,
    });
    return "";
  }

  const publicClient = await primaryWallet?.getPublicClient();
  const nonce = await publicClient.readContract({
    address: tokenAddress,
    abi: USDC_ABI,
    functionName: "nonces",
    args: [walletAddress],
  });

  const nonceInt = Number(nonce);

  // fetch this info from USDC contract
  const domain = {
    name: "USD Coin", // todo: get dynamic name from contract
    version: "2", // todo: check if this is correct
    chainId: isProduction ? avalanche.id : avalancheFuji.id,
    verifyingContract: tokenAddress,
  };

  const types = {
    Permit: [
      { name: "owner", type: "address" },
      { name: "spender", type: "address" },
      { name: "value", type: "uint256" },
      { name: "nonce", type: "uint256" },
      { name: "deadline", type: "uint256" },
    ],
  };

  const value = usdToUsdc(amount);

  const message = {
    owner: walletAddress,
    spender: spenderAddress,
    value: value,
    nonce: nonceInt,
    deadline,
  };
  const typedData = { primaryType: "Permit", domain, types, message };

  try {
    const walletClient = await primaryWallet.getWalletClient();
    const signature = await walletClient.signTypedData(typedData);

    // Split signature into v, r, s components
    const r = signature.slice(0, 66) as Hex;
    const s = `0x${signature.slice(66, 130)}` as Hex;
    const v = parseInt(signature.slice(130, 132), 16);

    // Encode all parameters together as expected by the contract
    const encodedData = encodeAbiParameters(
      [
        { type: "address" }, // owner
        { type: "address" }, // spender
        { type: "uint256" }, // value
        { type: "uint256" }, // deadline
        { type: "uint8" }, // v
        { type: "bytes32" }, // r
        { type: "bytes32" }, // s
      ],
      [walletAddress, spenderAddress, BigInt(value), BigInt(deadline), v, r, s]
    );

    return encodedData;
  } catch (error) {
    console.log({
      mstatus: "Error generating permit signature",
      error,
      typedData,
    });
    console.error(error);
    return "";
  }
};
