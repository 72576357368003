import React from "react";
import { HeartHandshakeIcon, TicketIcon } from "lucide-react";

import { Menubar, MenubarMenu, MenubarTrigger } from "@/components/ui/menubar";
import BattleIcon from "@/assets/svg/battle.svg?react";
import { Button } from "@/components/ui/button";
import { HorizontalScrollList } from "@/components/main";
import { cn } from "@/helpers/tailwind";

export enum ProfileScreens {
  Battles = "Battles",
  Tickets = "Tickets",
  Referrals = "Referrals",
}

export interface MenuItem {
  id: ProfileScreens;
  label: string;
  icon: React.ReactNode;
}

const menuItems: MenuItem[] = [
  {
    id: ProfileScreens.Battles,
    label: "My Battles",
    icon: <BattleIcon className="size-8" />,
  },
  {
    id: ProfileScreens.Tickets,
    label: "My Tickets",
    icon: <TicketIcon className="size-8 stroke-1" />,
  },
  {
    id: ProfileScreens.Referrals,
    label: "Referrals",
    icon: <HeartHandshakeIcon className="size-8 stroke-1" />,
  },
];

interface MenubarProps {
  className?: string;
  active: string;
  onChange: (menuItem: ProfileScreens) => void;
}

const Menu: React.FC<MenubarProps> = ({ className, active, onChange }) => (
  <Menubar className={cn("p-0 text-slate-500", className)}>
    <div className="hidden gap-4 sm:flex">
      {menuItems.map((item) => (
        <MenubarMenu key={item.id}>
          <MenubarTrigger
            className="cursor-pointer transition-all duration-200 hover:bg-accent"
            onClick={() => onChange(item.id)}
            data-state={active === item.id ? "open" : "close"}>
            {item.icon}
            <p className="ml-2 text-center text-[16px] font-medium">
              {item.label}
            </p>
          </MenubarTrigger>
        </MenubarMenu>
      ))}
    </div>
    <HorizontalScrollList showFades containerClassName="!ml-0 sm:hidden">
      {menuItems.map((item) => (
        <Button
          key={item.id}
          size="sm"
          variant="secondary"
          className={cn("rounded-full text-slate-400 hover:bg-blue-100", {
            "bg-blue-100 text-slate-900": active === item.id,
          })}
          onClick={() => onChange(item.id)}>
          {item.icon}
          {item.label}
        </Button>
      ))}
    </HorizontalScrollList>
  </Menubar>
);

export default React.memo(Menu);
