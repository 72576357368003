import React from "react";
import { CheckCheckIcon, HourglassIcon, PlusIcon } from "lucide-react";

import { cn } from "@/helpers/tailwind";
import {
  SelectTrigger,
  SelectItem,
  Select,
  SelectContent,
  SelectGroup,
  SelectValue,
} from "@/components/ui";
import { BattleStatus } from "@/types";

export type StatusFilter = "all" | BattleStatus;

const options = [
  { value: "all", label: "All" },
  {
    value: BattleStatus.Open,
    label: "Open for Bets",
    icon: <PlusIcon className="size-3.5" />,
  },
  {
    value: BattleStatus.Pending,
    label: "Waiting for Results",
    icon: <HourglassIcon className="size-3.5 stroke-2" />,
  },
  {
    value: BattleStatus.Completed,
    label: "Resolved",
    icon: <CheckCheckIcon className="size-3.5" />,
  },
];

interface StatusSelectProps {
  status: StatusFilter;
  setStatus: (status: StatusFilter) => void;
}

export const StatusSelect: React.FC<StatusSelectProps> = ({
  status,
  setStatus,
}) => (
  <Select
    value={status}
    onValueChange={(value) => setStatus(value as StatusFilter)}>
    <SelectTrigger
      className={cn(
        "flex-grow rounded-full border-slate-700 bg-slate-800 text-slate-400 lg:rounded-lg",
        "[&>span]:flex [&>span]:items-center [&>span]:gap-2",
        "lg:min-w-[190px] lg:border-slate-400 lg:bg-slate-900"
      )}>
      <SelectValue />
    </SelectTrigger>
    <SelectContent className="mt-1 border-slate-500 bg-slate-700">
      <SelectGroup className="p-1">
        {options.map(({ value, label, icon }) => (
          <SelectItem
            key={value}
            value={value}
            hideCheck
            className="!px-3 !py-2 [&>span>svg]:text-slate-400 [&>span]:flex [&>span]:items-center [&>span]:gap-2">
            {icon}
            <span>{label}</span>
          </SelectItem>
        ))}
      </SelectGroup>
    </SelectContent>
  </Select>
);
