import { useFormContext } from "react-hook-form";
import { RadioGroup } from "@/components/ui/radio-group";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import React from "react";
import WeekOption from "./WeekOption";
import dayjs from "dayjs";
import { getCurrentAndNextWeeks } from "@/helpers";

const WeekSelect: React.FC = () => {
  const { control } = useFormContext();
  const { currentWeek, nextWeek } = getCurrentAndNextWeeks();
  return (
    <FormField
      control={control}
      name="week"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <RadioGroup
              value={field.value}
              onValueChange={field.onChange}
              className="flex flex-1">
              <WeekOption
                type="Week A"
                isSelected={field.value === "Week A"}
                title={`Week ${currentWeek.week}`}
                subtitle={`${currentWeek.start} - ${currentWeek.end}`}
              />
              <WeekOption
                type="Week B"
                isSelected={field.value === "Week B"}
                title={`Week ${nextWeek.week}`}
                subtitle={`${nextWeek.start} - ${nextWeek.end}`}
              />
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default WeekSelect;
