import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { ArrowLeftIcon, ArrowRightIcon, ListIcon } from "lucide-react";

import { HypeboardTimeframe } from "@dripit/types";
import BattleIcon from "@/assets/svg/battle.svg?react";
import { SearchInput, HypeLeaderboard } from "./components";
import { PageWrapper } from "@/components/layout";
import { Button, ToggleFilter } from "@/components/main";
import CreateBattleButton from "@/components/layout/CreateBattle/CreateBattle";
import { BattleCard } from "../battles/components";
import { useBreakpoints } from "@/hooks";
import { cn } from "@/helpers/tailwind";
import { useHypeboard, useHypeboardBattles } from "@/api/hypeboardQueries";
import isEmpty from "lodash.isempty";
import { Skeleton } from "@/components/ui";
import { UrlParams } from "@/constants";
import { getRankedArtists, RankedHypeboardArtist } from "@/helpers";

const FILTER_TYPES = [
  { id: HypeboardTimeframe["7D"], labelKey: "7d" },
  { id: HypeboardTimeframe["30D"], labelKey: "30d" },
  { id: HypeboardTimeframe["YTD"], labelKey: "YTD" },
];

const VIEW_TYPES = [
  { id: "list", labelKey: "List", Icon: ListIcon },
  { id: "battles", labelKey: "Battles", Icon: BattleIcon },
];

const HypeBoardPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const isLoggedIn = useIsLoggedIn();
  const { isGreaterThan } = useBreakpoints();
  const [selectedFilterType, setSelectedFilterType] =
    React.useState<HypeboardTimeframe>(FILTER_TYPES[0].id);
  const [selectedViewType, setSelectedViewType] = React.useState<string>(
    VIEW_TYPES[0].id
  );
  const { data: hypeboardData, status: hypeboardStatus } = useHypeboard(id);
  const { data: battlesData, status: battlesStatus } = useHypeboardBattles(id);
  const rankedArtists = React.useMemo(
    () => getRankedArtists(hypeboardData?.artists),
    [hypeboardData?.artists]
  );
  const [artists, setArtists] = React.useState<RankedHypeboardArtist[]>(
    rankedArtists ?? []
  );

  const handleSearchArtists = (value: string) => {
    if (!hypeboardData) return;
    if (isEmpty(value)) {
      setArtists(rankedArtists);
      return;
    }
    setArtists(
      rankedArtists?.filter(
        (artist) =>
          artist.name.toLowerCase().includes(value.toLowerCase()) ||
          artist?.country?.toLowerCase().includes(value.toLowerCase())
      ) ?? []
    );
  };

  React.useEffect(() => {
    if (!isEmpty(rankedArtists)) {
      setArtists(rankedArtists ?? []);
    }
  }, [rankedArtists]);

  const content = {
    viewMoreBattles: isEmpty(battlesData?.results) ? null : (
      <Button
        className={cn(
          "h-min rounded-full bg-indigo-400/15 text-indigo-400",
          "hover:bg-indigo-400/25 hover:text-indigo-400"
        )}
        onClick={() =>
          navigate(`/battles?${UrlParams.HYPER_METER_TYPE}=${id}`)
        }>
        <span>View more</span>
        <ArrowRightIcon className="size-4" />
      </Button>
    ),
    battles: () => {
      if (battlesStatus == "pending") {
        return Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={index}
            className="h-72 w-full rounded-3xl bg-slate-800"
          />
        ));
      } else if (isEmpty(battlesData?.results)) {
        return (
          <p className="mt-4 text-center text-slate-400">
            No related battles at the moment.
          </p>
        );
      }
      return battlesData?.results
        .slice(0, 5)
        .map((battle) => <BattleCard key={battle.id} battle={battle} />);
    },
  };

  return (
    <PageWrapper
      separator={isGreaterThan("lg") ? "always" : undefined}
      leftSide={
        <div className="min-w-0 max-w-[600px] flex-grow">
          <div className="mb-8 flex gap-3">
            <Button
              className={cn(
                "mt-2 hidden size-6 flex-shrink-0 rounded-full border md:flex",
                "border-white bg-transparent p-0 text-white hover:bg-transparent"
              )}
              onClick={() => navigate(-1)}>
              <ArrowLeftIcon className="size-4" />
            </Button>
            <h1 className="font-phonk text-3xl">{hypeboardData?.name}</h1>
          </div>
          <div className="mb-4 flex flex-col-reverse items-center justify-between gap-2 lg:mb-6 lg:flex-row">
            <div className="flex w-full items-center justify-between gap-2 lg:w-auto">
              <ToggleFilter<string>
                className="rounded-full lg:hidden [&>*]:h-8 [&_svg]:size-[16x]"
                items={VIEW_TYPES}
                selectedId={selectedViewType}
                onChange={setSelectedViewType}
              />
              <div className="relative">
                <ToggleFilter<HypeboardTimeframe>
                  className={cn(
                    "rounded-full lg:rounded-md [&>*]:h-8 [&>*]:w-10 lg:[&>*]:w-12",
                    "opacity-100 transition-opacity duration-200 ease-in-out",
                    {
                      "pointer-events-none opacity-0":
                        selectedViewType === "battles" && !isGreaterThan("lg"),
                    }
                  )}
                  items={FILTER_TYPES}
                  selectedId={selectedFilterType}
                  disabled={hypeboardStatus === "pending"}
                  onChange={setSelectedFilterType}
                />
                <div
                  className={cn(
                    "absolute right-0 top-1/2 -translate-y-1/2",
                    "opacity-100 transition-opacity duration-200 ease-in-out",
                    {
                      "pointer-events-none opacity-0":
                        selectedViewType === "list" && !isGreaterThan("lg"),
                      hidden: isGreaterThan("lg"),
                    }
                  )}>
                  {content.viewMoreBattles}
                </div>
              </div>
            </div>
            <div className="mb-4 flex w-full flex-grow items-center justify-end gap-2 lg:mb-0 lg:w-auto">
              <SearchInput
                className="lg:max-w-[300px]"
                placeholder="Search by artist name..."
                disabled={hypeboardStatus === "pending"}
                onSearch={handleSearchArtists}
              />
              {isLoggedIn && <CreateBattleButton />}
            </div>
          </div>
          <div className="relative">
            <HypeLeaderboard
              className={cn(
                "opacity-100 transition-opacity duration-300 ease-in-out",
                {
                  "pointer-events-none hidden opacity-0":
                    selectedViewType === "battles" && !isGreaterThan("lg"),
                }
              )}
              loading={hypeboardStatus === "pending"}
              timeframe={
                FILTER_TYPES.find(({ id }) => id === selectedFilterType)?.id ??
                HypeboardTimeframe["7D"]
              }
              artists={artists}
            />

            <div
              className={cn(
                "absolute left-0 top-0 w-full pb-12",
                "opacity-100 transition-opacity duration-300 ease-in-out",
                {
                  "pointer-events-none hidden opacity-0":
                    selectedViewType === "list",
                  hidden: isGreaterThan("lg"),
                }
              )}>
              <div className="flex flex-col gap-4">{content.battles()}</div>
            </div>
          </div>
        </div>
      }
      rightSide={
        <div className="hidden min-w-0 max-w-[600px] flex-grow lg:block">
          <div className="mb-1 flex items-center gap-2 text-indigo-300">
            <BattleIcon className="size-6" />
            <p>Related</p>
          </div>
          <div className="mb-4 flex items-center justify-between gap-2">
            <h2 className="font-phonk text-2xl text-indigo-200">Battles</h2>
            {content.viewMoreBattles}
          </div>
          <div className="flex flex-col gap-4">{content.battles()}</div>
        </div>
      }
    />
  );
};

export default HypeBoardPage;
