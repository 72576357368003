import { useQuery } from "@tanstack/react-query";
import { client } from "../base";
import { queryKeys } from "../constants";

// TODO - use useQuery instead
const getSpotifyToken = () =>
  useQuery<any>({
    queryKey: [queryKeys.SPOTIFY_TOKEN],
    queryFn: async () => {
      const res = await fetch("https://accounts.spotify.com/api/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: import.meta.env.VITE_SPOTIFY_CLIENT_ID,
          client_secret: import.meta.env.VITE_SPOTIFY_CLIENT_SECRET,
        }),
      });
      const resolvedRes = await res.json();
      return resolvedRes;
    },
    staleTime: 10_000,
  });

const getAccessToken = async () => {
  const response = await fetch("https://accounts.spotify.com/api/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "client_credentials",
      client_id: import.meta.env.VITE_SPOTIFY_CLIENT_ID,
      client_secret: import.meta.env.VITE_SPOTIFY_CLIENT_SECRET,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to fetch access token");
  }

  const data = await response.json();
  return data.access_token;
};

const searchSpotify = (
  query: string,
  token: string,
  type: string,
  limit: number = 5
) =>
  useQuery<any>({
    queryKey: [queryKeys.SPOTIFY_SEARCH, token],
    queryFn: async () => {
      const res = await fetch(
        `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=${type.toLowerCase()}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const resolvedRes = await res.json();
      return resolvedRes;
    },
    staleTime: 10_000,
    enabled: !!token && (query.length > 0 || !query),
  });

const searchItems = async (
  query: string,
  token: string,
  type: string,
  limit: number = 5
) => {
  if (!query) return [];

  const response = await fetch(
    `https://api.spotify.com/v1/search?q=${encodeURIComponent(query)}&type=${type.toLowerCase()}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch ${type.toLowerCase()}s`);
  }

  const data = await response.json();
  return data[`${type.toLowerCase()}s`].items;
};

export { getAccessToken, searchItems, getSpotifyToken, searchSpotify };
