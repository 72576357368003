import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useIsLoggedIn } from "@dynamic-labs/sdk-react-core";
import { AlignJustifyIcon, LogInIcon, LogOutIcon, XIcon } from "lucide-react";

import { cn } from "@/helpers/tailwind";
import { authActions } from "@/store";
import MobileMenuCurve from "@/assets/svg/mobile-menu-curve.svg?react";
import { Button } from "@/components/ui";
import { mobileMainNavItems, mobileSecondaryNavItems } from "@/constants";
import { useTranslate } from "@/hooks";

export const MobileBottomNav: React.FC = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div
        className={cn(
          "fixed left-0 top-0 z-40 h-full w-full bg-background px-4 py-12 md:hidden",
          "pointer-events-none opacity-0 transition-all duration-200 ease-in-out",
          { "pointer-events-auto opacity-100": isOpen }
        )}>
        <p className="mb-8 font-phonk text-3xl text-slate-400">Choose Menu</p>
        <div className="grid grid-cols-3 justify-items-center gap-3">
          {mobileSecondaryNavItems.map((item) => (
            <NavButton
              key={item.path}
              variant="conventional"
              onClick={() => setIsOpen(false)}
              {...item}
            />
          ))}
        </div>
      </div>
      <div className="fixed bottom-0 z-40 flex w-full items-end justify-center bg-background px-2 pb-4 pt-0 sm:px-10 sm:pb-6 md:hidden">
        <div className="pointer-events-none absolute inset-x-0 bottom-full h-10 w-full bg-gradient-to-t from-background to-transparent" />
        <div className="w-full max-w-md">
          <div className="mx-auto h-3 w-full bg-background" />
          <Button
            className={cn(
              "z-1 relative mx-auto mb-[-16px] flex items-center justify-center",
              "size-9 rounded-full border border-slate-500 bg-white"
            )}
            onClick={() => setIsOpen(!isOpen)}>
            <AlignJustifyIcon
              className={cn(
                "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
                "scale-100 opacity-100 transition-opacity duration-200 ease-in-out",
                { "scale-0 opacity-0": isOpen }
              )}
            />
            <XIcon
              className={cn(
                "scale-0 opacity-0 transition-opacity duration-200 ease-in-out",
                { "scale-100 opacity-100": isOpen }
              )}
            />
          </Button>
          <MobileMenuCurve className="mx-auto mb-[-24px] h-6 text-background" />
          <div className="flex h-12 items-center justify-between rounded-full border border-slate-500 px-6">
            <Button
              className="flex h-10 flex-col items-center justify-center gap-0 bg-transparent p-0.5 text-white hover:bg-transparent focus:bg-transparent"
              onClick={() => dispatch(authActions.toggleTriggerLogin())}>
              {isLoggedIn ? (
                <LogOutIcon className="size-5" />
              ) : (
                <LogInIcon className="size-5" />
              )}
              <span className="h-4 w-12 text-[10px]">
                {isLoggedIn ? "Log out" : "Log in"}
              </span>
            </Button>
            {mobileMainNavItems.slice(0, 1).map((item) => (
              <NavButton key={item.path} {...item} />
            ))}
            <div className="w-12" />
            {mobileMainNavItems.slice(1).map((item) => (
              <NavButton key={item.path} {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

interface NavButtonProps {
  namePath: string;
  path: string;
  icon: React.ReactNode;
  variant?: "conventional" | "miniature";
  onClick?: () => void;
}

const NavButton: React.FC<NavButtonProps> = ({
  namePath,
  path,
  icon,
  variant = "miniature",
  onClick,
}) => {
  const t = useTranslate();

  const content = {
    miniature: (
      <NavLink
        to={path}
        className={({ isActive }) =>
          cn("flex h-10 flex-col items-center justify-center p-0.5", {
            "rounded-3xl bg-slate-700 text-white": isActive,
          })
        }
        onClick={onClick}>
        {({ isActive }) => (
          <>
            <div className="xs:scale-100 scale-75">{icon}</div>
            {isActive ? (
              <div className="xs:h-3 flex h-4 w-12 items-center justify-center">
                <div className="h-1 w-1 rounded-full bg-white" />
              </div>
            ) : (
              <div className="flex h-4 w-12 items-center justify-center">
                <span className="truncate text-[10px]">{t(namePath)}</span>
              </div>
            )}
          </>
        )}
      </NavLink>
    ),
    conventional: (
      <NavLink
        to={path}
        className={({ isActive }) =>
          cn(
            "flex flex-col items-center justify-center",
            "aspect-square max-h-28 w-full rounded-3xl bg-slate-800 p-2",
            "transition-colors duration-200 ease-in-out",
            { "bg-slate-700": isActive }
          )
        }
        onClick={onClick}>
        <div className="mb-3 sm:mb-4">{icon}</div>
        <div className="flex h-4 w-full items-center justify-center">
          <span className="truncate text-xs sm:text-sm">{t(namePath)}</span>
        </div>
      </NavLink>
    ),
  };

  return content[variant];
};
