// Ensure index is between 0 and 100
export const getHypeColor = (index: number) => {
  const safeIndex = Math.max(0, Math.min(100, index));

  // Helper function to interpolate between two hex colors
  const interpolateColor = (color1: string, color2: string, factor: number) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);

    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    const r = Math.round(r1 + (r2 - r1) * factor);
    const g = Math.round(g1 + (g2 - g1) * factor);
    const b = Math.round(b1 + (b2 - b1) * factor);

    return `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  };

  const colors = ["#3AFFFF ", "#66D466 ", "#E2D131 ", "#FF3A3A"];

  // Define color segments
  if (safeIndex <= 33) {
    // Cyan to Green (0-33)
    return interpolateColor(colors[0], colors[1], safeIndex / 33);
  } else if (safeIndex <= 66) {
    // Green to Yellow (34-66)
    return interpolateColor(colors[1], colors[2], (safeIndex - 33) / 33);
  } else {
    // Yellow to Red (67-100)
    return interpolateColor(colors[2], colors[3], (safeIndex - 66) / 34);
  }
};
