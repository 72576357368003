import React, { ReactNode } from "react";
import { cn } from "@/helpers/tailwind";

export type ColumnRef =
  | "left"
  | "col1"
  | "col2"
  | "col3"
  | "col4"
  | "col5"
  | "col6"
  | "col7"
  | "col8"
  | "col9"
  | "col10"
  | "col11"
  | "col12"
  | "right"
  | "end"
  | number;

export type Variant = "sm" | "md" | "lg";

export type ClassKey = "col-start" | "col-end" | "col-span";

export type ColReference<T> = {
  [key in Variant]?: T;
};

/**
 * Reusable common presets
 */
const presetMap = {
  default: {
    start: "col1",
    span: {
      sm: 2,
      md: 8,
      lg: 12,
    },
  },
};

export type Preset = keyof typeof presetMap;

export interface ComponentProps {
  className?: string;
  children?: ReactNode;
}
export interface CellProps {
  className?: string;
  start?: ColReference<ColumnRef> | ColumnRef;
  end?: ColReference<ColumnRef> | ColumnRef;
  span?: ColReference<number> | number;
  component?:
    | React.FunctionComponent<ComponentProps>
    | keyof JSX.IntrinsicElements;
  preset?: Preset;
  children?: ReactNode;
}

const DefaultComponent = ({ children, className }: ComponentProps) => (
  <div className={className}>{children}</div>
);

/**
 * Builds className from params
 * If a scalar is provided, then assign to all variants explicitly
 * to allow combinations of scalar vs object keys to work
 * @param key
 * @param value
 * @returns className
 */
const buildClass = (
  key: ClassKey,
  value: ColReference<ColumnRef> | ColumnRef
) =>
  typeof value == "object"
    ? Object.keys(value).map(
        (variant) => `${variant}:${key}-${value[variant as Variant]}`
      )
    : ["sm", "md", "lg"].map((variant) => `${variant}:${key}-${value}`);

const classNamesFor = ({ start, end, span, className }: CellProps): string =>
  cn(
    // for displays under 640px, the column will span 2 columns
    "col-start-1 col-span-2",
    span && buildClass("col-span", span),
    start && buildClass("col-start", start),
    end && buildClass("col-end", end),
    className
  );

export const Cell = ({ children, component, preset, ...props }: CellProps) => {
  const Component = component || DefaultComponent;
  const presetValues = preset && preset in presetMap ? presetMap[preset] : {};

  return (
    <Component className={classNamesFor({ ...presetValues, ...props })}>
      {children}
    </Component>
  );
};

export default Cell;
