import React from "react";
import { CirclePlusIcon, DollarSignIcon } from "lucide-react";

import { Button, Input } from "@/components/ui";
import { cn } from "@/helpers/tailwind";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

interface SizeInputProps {
  size: number | string;
  disabled?: boolean;
  setSize: (size: number | string) => void;
}

const SizeInput: React.FC<SizeInputProps> = ({
  size,
  disabled = false,
  setSize,
}) => {
  const { balance } = useSelector((state: RootState) => state.wallet);
  const isValidInput = (value: number | string) => {
    if (value === "") return true;
    if (value === ".") return false;

    const decimalPlaces = value.toString().split(".")[1]?.length ?? 0;
    if (decimalPlaces > 2) return false;

    const numValue = Number(value);
    return numValue >= 0 && numValue <= Number(Number(balance ?? 0).toFixed(2));
  };

  return (
    <div className="relative w-full">
      <DollarSignIcon className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-slate-400" />
      <Input
        type="number"
        inputMode="decimal"
        value={size}
        min={0}
        disabled={disabled}
        max={Number(balance ?? 0).toFixed(2)}
        placeholder="10.00"
        className={cn(
          "flex w-full select-text rounded-lg pl-16 pr-[72px] text-right",
          "border-slate-500 bg-transparent text-sm text-white placeholder:text-slate-400"
        )}
        onChange={(e) => {
          const value = e.target.value;
          if (isValidInput(value)) {
            setSize(value);
          }
        }}
        onKeyDown={(e) => {
          if (["-", "+", "e", "E"].includes(e.key)) e.preventDefault();
        }}
        onPaste={(e) => {
          const pastedText = e.clipboardData.getData("text");
          if (!isValidInput(pastedText)) {
            e.preventDefault();
          }
        }}
      />
      <Button
        className={cn(
          "absolute right-0 top-1/2 mr-2 h-6 -translate-y-1/2 gap-1 px-1.5 text-xs"
        )}
        disabled={disabled}
        onClick={() => setSize(Number(balance).toFixed(2))}>
        <CirclePlusIcon className="size-3.5" />
        <span>Max</span>
      </Button>
    </div>
  );
};

export default SizeInput;
