import React from "react";
import { SearchIcon } from "lucide-react";

import { Input } from "@/components/ui";
import { cn } from "@/helpers/tailwind";

interface SearchInputProps {
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onSearch?: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  className,
  placeholder,
  disabled,
  onSearch,
}) => {
  const [search, setSearch] = React.useState("");
  const [previousSearch, setPreviousSearch] = React.useState(search);
  const [isInitialRender, setIsInitialRender] = React.useState(true);

  React.useEffect(() => {
    let debounceTimeout: NodeJS.Timeout;

    const debounce = () => {
      if (isInitialRender) return;
      const debounceDelay = 300;
      debounceTimeout = setTimeout(() => {
        if (search !== previousSearch) {
          setPreviousSearch(search);
          onSearch?.(search);
        }
      }, debounceDelay);
    };

    debounce();
    return () => clearTimeout(debounceTimeout);
  }, [search, previousSearch, onSearch]);

  React.useEffect(() => {
    setIsInitialRender(false);
  }, []);

  return (
    <div className={cn("relative flex-grow", className)}>
      <SearchIcon className="absolute left-2.5 top-1/2 mt-0 size-4 -translate-y-1/2 text-slate-400" />
      <Input
        className="h-9 w-full rounded-full border-slate-400 pl-9 font-light lg:rounded-lg"
        value={search}
        disabled={disabled}
        placeholder={placeholder ?? "Search..."}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
};
