import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  BanIcon,
  ChevronRightIcon,
  DollarSignIcon,
  HourglassIcon,
  TrophyIcon,
  XIcon,
} from "lucide-react";
import { Battle, BattlesType, BattleArtist } from "@dripit/types";

import { Button, SizeInput, VerticalScrollList } from "@/components/main";
import { cn } from "@/helpers/tailwind";
import {
  formatBalance,
  getArtistHypeMeter,
  getBattleArtists,
  getBattleStatus,
  getUserBattleStatus,
  usdcToUsd,
} from "@/helpers";
import { Separator } from "@/components/ui";
import { RootState } from "@/store";
import isNil from "lodash.isnil";
import { Cta } from "./BattleCardUtils";
import { BattleStatus, UserBattleStatus } from "@/types";

export interface BattleCardProps {
  battle: Battle;
}

export const BattleCard = ({ battle }: BattleCardProps) => {
  const navigate = useNavigate();
  const {
    id,
    type,
    total: volume,
    name,
    start_date,
    end_date,
    winner_id,
  } = battle;
  const status = React.useMemo(
    () => getBattleStatus({ start_date, end_date, winner_id }),
    [start_date, end_date]
  );
  const artists = React.useMemo(
    () => getBattleArtists(battle?.battleSides),
    [battle.battleSides]
  );
  const userStatus = React.useMemo(() => getUserBattleStatus(), [battle]);
  const { balance } = useSelector((state: RootState) => state.wallet);
  const [size, setSize] = React.useState<number | string>("");
  const [selectedArtist, setSelectedArtist] =
    React.useState<BattleArtist | null>(null);
  const [showBuyStep, setShowBuyStep] = React.useState(false);
  const potentialProfitPerc = 28.53;

  React.useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!showBuyStep) {
      timer = setTimeout(() => {
        setSize("");
        setSelectedArtist(null);
      }, 350);
    }

    return () => clearTimeout(timer);
  }, [showBuyStep]);

  const getArtistPosition = (artistId: BattleArtist["id"]) => {
    // todo: if logged in, and user has position on artist, return that
    return undefined;
  };

  const positionLabel = (position?: number | null) =>
    !isNil(position) &&
    position > 0 && (
      <div
        className={cn(
          "rounded-md px-1.5 py-1 text-xs font-semibold",
          "bg-indigo-400/15 text-indigo-400",
          {
            "bg-teal-400/15 text-teal-400": userStatus === UserBattleStatus.Won,
            "bg-red-400/15 text-red-400 opacity-50":
              userStatus === UserBattleStatus.Lost,
            "bg-orange-400/15 text-orange-400":
              userStatus === UserBattleStatus.Pending,
            "bg-slate-700 text-slate-400 opacity-50":
              userStatus === UserBattleStatus.Canceled,
          }
        )}>
        <p>$ {formatBalance(usdcToUsd(position))}</p>
      </div>
    );

  const contentHype = (
    <VerticalScrollList
      showFades
      fadeClassName="from-slate-800"
      containerClassName="flex h-[154px] flex-col gap-2">
      {artists.map((artist) => (
        <div
          key={artist.id}
          className="flex w-full items-center justify-between gap-3 text-slate-400">
          <img
            src={artist.image ?? ""}
            alt={artist.name}
            className="size-9 rounded-sm"
          />
          <p className="flex-grow truncate">{artist.name}</p>
          {positionLabel(getArtistPosition(artist.id))}
          <p className="text-xl">
            {getArtistHypeMeter(artist?.hypemeter_scores).toFixed(0)}%
          </p>
          <Cta
            minimal
            status={userStatus}
            position={getArtistPosition(artist.id)}
            artistId={artist.id}
            winnerId={battle.winner_id}
            onClick={() => {
              setSelectedArtist(artist);
              setShowBuyStep(true);
            }}
          />
        </div>
      ))}
    </VerticalScrollList>
  );

  const content1v1 = (
    <div className="flex justify-between gap-3">
      {artists.map((artist) => (
        <div
          key={artist.id}
          className={cn(
            "aspect-square bg-cover bg-center bg-no-repeat object-cover bg-blend-darken",
            "relative basis-1/2 overflow-hidden rounded-xl",
            "flex flex-col justify-end p-2"
          )}
          style={{ backgroundImage: `url(${artist.image})` }}>
          <div className="z-10 text-slate-400">
            <p className="truncate">{artist.name}</p>
            <div className="mb-1.5 flex items-center justify-between">
              <p className="text-2xl">
                {getArtistHypeMeter(artist?.hypemeter_scores).toFixed(0)}%
              </p>
              {positionLabel(getArtistPosition(artist.id))}
            </div>
            <Cta
              status={userStatus}
              position={getArtistPosition(artist.id)}
              artistId={artist.id}
              winnerId={battle.winner_id}
              onClick={() => {
                setSelectedArtist(artist);
                setShowBuyStep(true);
              }}
            />
          </div>
          <div
            className={cn(
              "absolute inset-x-0 bottom-0 z-0 h-full",
              "bg-gradient-to-t from-slate-900 from-[0%] via-slate-900 via-[20%] to-slate-900/10"
            )}
          />
        </div>
      ))}
    </div>
  );

  const contentBuy = (
    <div
      className={cn(
        "absolute left-0 top-0 z-20 h-full w-full overflow-auto bg-slate-800",
        "flex flex-col justify-between",
        "opacity-100 transition-all duration-300 ease-in-out",
        { "pointer-events-none opacity-0": !showBuyStep }
      )}>
      <div className="flex w-full items-center justify-between gap-3 text-slate-400">
        <img
          src={selectedArtist?.image ?? ""}
          alt={selectedArtist?.name ?? ""}
          className="size-9 rounded-sm"
        />
        <p className="flex-grow truncate">{selectedArtist?.name}</p>
        {positionLabel(getArtistPosition(selectedArtist?.id ?? 0))}
        <p className="text-xl">
          {selectedArtist?.hypemeter_scores?.[0]?.score.toFixed(0)}%
        </p>
      </div>
      <Separator className="my-2 h-0 border-t border-dashed border-slate-600" />
      <div className="flex-grow">
        {/* min amount */}
        <div className="mb-0.5 mr-[128px] flex items-center justify-between">
          <p className="text-sm">Amount</p>
          <p className="font-geist text-xs text-indigo-300">
            <span className="mr-2">Min:</span>
            <span>$ 1</span>
          </p>
        </div>
        {/* input */}
        <div className="flex items-center justify-between gap-2">
          <div className="flex-grow">
            <SizeInput
              size={size}
              setSize={setSize}
              // disabled={loading}
            />
          </div>
          <div className="w-[120px] rounded-md bg-green-400/15 p-1.5 text-center text-green-300">
            <span className="mb-1 block text-[10px] leading-[10px]">
              Potential profit
            </span>
            <div className="flex items-center justify-center gap-1 text-[11px] font-semibold leading-[10px]">
              <span>
                $ {((Number(size ?? 0) * potentialProfitPerc) / 100).toFixed(2)}
              </span>
              <span className="h-3 w-0 border border-r-green-300" />
              <span>{potentialProfitPerc.toFixed(2)}%</span>
            </div>
          </div>
        </div>
        {/* balance */}
        <div className="mr-[128px] mt-0.5 flex items-center justify-between gap-2 text-xs text-slate-400">
          <span>Balance:</span>
          <span>$ {formatBalance(Number(balance ?? 0))}</span>
        </div>
      </div>
      <Button variant="tertiary">
        <DollarSignIcon />
        <span>Buy</span>
        <span className="h-4 w-0 border border-l-transparent border-r-indigo-300" />
        <span className="font-light text-indigo-300">
          {selectedArtist?.name ?? ""}
        </span>
      </Button>
    </div>
  );

  return (
    <div
      className={cn(
        "relative flex flex-col justify-between rounded-3xl bg-slate-800 p-6",
        "border border-transparent transition-colors duration-200 ease-in-out",
        {
          "border-indigo-400":
            showBuyStep &&
            [
              BattlesType.hypeboard_battle,
              BattlesType.head2head_battle,
            ].includes(type),
          "border-green-400":
            showBuyStep && type === BattlesType.artist_hype_change,
        }
      )}>
      <div className="mb-4 flex justify-between gap-4">
        <h3
          className="line-clamp-2 cursor-pointer"
          onClick={() => navigate(`/battles/${id}`)}>
          {name}
        </h3>
        <div className="flex items-start gap-1">
          {type === BattlesType.hypeboard_battle && status === "pending" && (
            <div
              className={cn(
                "size-7 rounded-md bg-orange-400/15 px-1.5 py-1 text-orange-400",
                "flex items-center justify-center"
              )}>
              <HourglassIcon className="size-4" />
            </div>
          )}
          {type === BattlesType.hypeboard_battle &&
            status === BattleStatus.Canceled && (
              <div
                className={cn(
                  "size-7 rounded-md bg-slate-700 px-1.5 py-1 text-slate-400",
                  "flex items-center justify-center"
                )}>
                <BanIcon className="size-4" />
              </div>
            )}
          <div className="relative">
            <Button
              className={cn(
                "bg-slate-900 text-white hover:bg-slate-900/70",
                "size-7 flex-shrink-0 border border-slate-700 p-0",
                "opacity-100 transition-all duration-200 ease-in-out",
                { "pointer-events-none opacity-0": showBuyStep }
              )}
              onClick={() => navigate(`/battles/${id}`)}>
              <ChevronRightIcon />
            </Button>
            <Button
              className={cn(
                "hover:text-initial bg-white text-slate-900 hover:bg-white/80",
                "size-7 flex-shrink-0 border border-slate-700 p-0",
                "absolute right-0 top-0",
                "opacity-100 transition-all duration-200 ease-in-out",
                { "pointer-events-none opacity-0": !showBuyStep }
              )}
              onClick={() => setShowBuyStep(false)}>
              <XIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className="relative">
        {contentBuy}
        <div className="mb-4 flex-grow">
          {type === BattlesType.hypeboard_battle && contentHype}
          {type === BattlesType.head2head_battle && content1v1}
        </div>
        <div className="flex justify-between gap-3">
          <div className="flex items-center gap-1 text-xs text-slate-500">
            <TrophyIcon className="size-3.5" />
            <span>Volume:</span>
            <span className="text-white">
              $ {formatBalance(usdcToUsd(Number(volume ?? 0)))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
