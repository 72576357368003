import dayjs from "dayjs";

import {
  Battle,
  BattleArtist,
  HypeboardTimeframe,
  HypeboardType,
} from "@dripit/types";
import { BattleStatus, UserBattleStatus } from "@/types";
import isNil from "lodash.isnil";

type GetBattleStatus = (battle: Partial<Battle>) => BattleStatus;

export const getBattleStatus: GetBattleStatus = ({
  start_date,
  end_date,
  winner_id,
}) => {
  if (dayjs().isBefore(dayjs(start_date))) {
    return BattleStatus.Open;
  }

  if (dayjs().isBefore(dayjs(end_date))) {
    return BattleStatus.Pending;
  }

  if (dayjs().isAfter(dayjs(end_date))) {
    if (winner_id === null) {
      return BattleStatus.Canceled;
    }
    if (!isNil(winner_id)) {
      return BattleStatus.Completed;
    }
  }

  return BattleStatus.Pending;
};

export const getBattleStatusKeyText = (battle: Partial<Battle>) => {
  const status = getBattleStatus(battle);

  if (status === BattleStatus.Open) return "battles.starts-in";
  if (status === BattleStatus.Completed) return "battles.ended";
  // if (status === BattleStatus.Canceled) return "battles.canceled";
  return "battles.ends-in";
};

// interface GetUserBattleStatusProps {
//   start_date: Battle["start_date"];
//   end_date: Battle["end_date"];
// }

type GetUserBattleStatus = () // props: GetUserBattleStatusProps
=> UserBattleStatus;

export const getUserBattleStatus: GetUserBattleStatus = () => {
  // logic here
  return UserBattleStatus.Active;
};

export const getBattleArtists = (sides?: Battle["battleSides"]) => {
  const artists = sides?.reduce((artists, side) => {
    if (side?.artist) {
      artists.push(side.artist);
    }
    return artists;
  }, [] as BattleArtist[]);

  const sortedArtists =
    artists?.sort((a, b) => {
      const scoreA = a?.hypemeter_scores?.[0]?.score ?? 0;
      const scoreB = b?.hypemeter_scores?.[0]?.score ?? 0;
      return scoreB - scoreA;
    }) ?? [];

  return sortedArtists;
};

export const getArtistHypeMeter = (
  scores: BattleArtist["hypemeter_scores"],
  timeframe: HypeboardTimeframe = HypeboardTimeframe["7D"]
) => scores.find((obj) => obj.timeFrame === timeframe)?.score ?? 0;

export const getBattleHypeboardTitle = (
  hypeboard_id: Battle["hypeboard_id"]
) => {
  if (hypeboard_id === HypeboardType.GlobalArtists) {
    return "Global Artists";
  }
  return "Unkown Hypeboard";
};
