import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { Calendar, Music, CalendarRange } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { getAccessToken } from "@/api/spotify/requests";
import UserMusic from "@/assets/svg/user-music.svg?react";
import VS from "@/assets/svg/vs-battle.svg?react";
import SpotifySearch from "./SpotifySearch";
import WeekSelect from "./WeekSelect";
import { resolveImage } from "@/helpers";

const StepBattleOptions = () => {
  const { control, watch } = useFormContext();
  const challengeType = watch("challengeType");
  const selectedItems = watch("selectedItems");
  const frm = watch();

  const { error: tokenError } = useQuery({
    queryKey: ["spotifyToken"],
    queryFn: getAccessToken,
    staleTime: 3540000, // 59 minutes
  });

  const placeholderIcon =
    challengeType === "Artist" ? (
      <UserMusic className={`h-5 w-5`} />
    ) : (
      <Music className={`h-5 w-5`} />
    );

  return (
    <FormField
      control={control}
      name="selectedItems"
      defaultValue={{ sideA: null, sideB: null }}
      render={() => (
        <FormItem className="mt-7">
          <FormControl>
            <div>
              <Card className="mb-14 rounded-xl bg-primary-foreground">
                <CardContent className="p-4">
                  <div className="flex h-full justify-between space-x-4">
                    <div className="space-between flex flex-col justify-between">
                      <div className="flex gap-4">
                        <div
                          className={`${!selectedItems?.sideA ? "rounded-lg bg-secondary p-2" : ""}`}>
                          {selectedItems?.sideA?.images.length && (
                            <img
                              src={resolveImage(
                                selectedItems?.sideA?.images[0].url || null
                              )}
                              className={`h-9 w-9 rounded-lg`}
                            />
                          )}
                          {!selectedItems?.sideA && placeholderIcon}
                        </div>
                        <h3 className="text-lg font-semibold text-foreground">
                          {challengeType}
                        </h3>
                      </div>
                      <span className="my-2 mr-2 pl-16 text-lg text-muted-foreground">
                        <VS />
                      </span>
                      <div className="flex gap-4">
                        <div
                          className={`${!selectedItems?.sideB ? "rounded-lg bg-secondary p-2" : ""}`}>
                          {selectedItems?.sideB?.images.length && (
                            <img
                              src={resolveImage(
                                selectedItems?.sideB?.images[0].url || null
                              )}
                              className={`h-9 w-9 rounded-lg`}
                            />
                          )}
                          {!selectedItems?.sideB && placeholderIcon}
                        </div>
                        <h3 className="text-lg font-semibold text-foreground">
                          {challengeType}
                        </h3>
                      </div>
                    </div>
                    <div className="flex max-w-sm flex-1 flex-col justify-between">
                      {/* Side A */}
                      <SpotifySearch isSideA={true} />
                      {/* Side B */}
                      <SpotifySearch />
                    </div>
                  </div>
                </CardContent>
              </Card>
              <div className="mt-6 flex flex-col space-y-3">
                <div className="flex items-center space-x-2">
                  <CalendarRange className="w-5 text-slate-300" />
                  <h5 className="font-geist text-slate-300">Select Week</h5>
                </div>
                <WeekSelect />
              </div>
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default StepBattleOptions;
