import React from "react";
import { HypeboardArtist, HypeboardTimeframe } from "@dripit/types";

import StarIcon from "@/assets/svg/star-1.svg?react";
import BgPatternSvg from "@/assets/svg/lines-pattern-2.svg?react";
import { cn } from "@/helpers/tailwind";
import { ChevronRightIcon, Share2Icon } from "lucide-react";
import { Button, VerticalScrollList } from "@/components/main";

interface HypeBoardCardProps {
  title: string;
  artists: HypeboardArtist[];
  hidePattern?: boolean;
  sortingTimeframe?: HypeboardTimeframe;
  onClick?: () => void;
}

export const HypeBoardCard: React.FC<HypeBoardCardProps> = ({
  title,
  artists,
  hidePattern = false,
  sortingTimeframe = HypeboardTimeframe["7D"],
  onClick,
}) => {
  const sortedArtists = React.useMemo(
    () =>
      [...artists].sort(
        (a, b) =>
          (b.timeFrames?.find(({ timeFrame }) => timeFrame === sortingTimeframe)
            ?.score ?? 0) -
          (a.timeFrames?.find(({ timeFrame }) => timeFrame === sortingTimeframe)
            ?.score ?? 0)
      ),
    [artists]
  );

  return (
    <div className="relative overflow-hidden rounded-3xl bg-slate-800 p-6 !pb-2">
      <BgPatternSvg
        className={cn(
          "absolute bottom-[-2%] right-[-15%]",
          "z-10 aspect-[1/1] max-h-[110%] w-auto",
          { hidden: hidePattern }
        )}
      />
      <div className="relative z-20 mb-3 flex items-center justify-between gap-4">
        <h3 className="cursor-pointer truncate font-phonk" onClick={onClick}>
          {title}
        </h3>
        <Button
          className={cn(
            "bg-slate-900 text-white hover:bg-slate-900/70",
            "size-7 flex-shrink-0 border border-slate-700 p-0",
            "opacity-100 transition-all duration-200 ease-in-out"
          )}
          onClick={onClick}>
          <ChevronRightIcon />
        </Button>
      </div>
      <div className="flex items-center justify-between gap-2">
        <VerticalScrollList
          showFades
          fadeClassName="from-slate-800"
          containerClassName="h-36 [&>*:first-child]:gap-3.5">
          {sortedArtists.map((artist, index) => (
            <div
              key={artist.id}
              className="flex w-full items-center gap-2 text-slate-500">
              <div
                className={cn(
                  "flex size-5 shrink-0 items-center justify-center rounded-full border border-slate-500"
                )}>
                <span className="font-phonk text-[8px]">{index + 1}</span>
              </div>
              <p className="truncate text-left font-geist text-xs">
                {artist.name}
              </p>
            </div>
          ))}
        </VerticalScrollList>
        <div className="relative z-20 flex items-center justify-center gap-0">
          {[sortedArtists[1], sortedArtists[0], sortedArtists[2]].map(
            (artist, index) => (
              <div
                key={artist.id}
                className={cn(
                  "bg-gradient-to-t from-[#40487F]/50 to-[#1E293B] backdrop-blur-[2.09px]",
                  "relative flex size-[70px] items-center justify-center rounded-full",
                  {
                    "z-30 mb-[45px] size-[96px]": index === 1,
                    "mb-[-25px] mr-[-25px]": index === 0,
                    "mb-[-25px] ml-[-25px]": index === 2,
                  }
                )}>
                <div
                  className="size-[80%] rounded-full object-cover bg-blend-darken"
                  style={{
                    backgroundImage: `url(${artist.image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                />
                {/* <span>{artist.name}</span> */}
                <div
                  className={cn(
                    "absolute bottom-[-15px] left-1/2 size-7 -translate-x-1/2 text-[11px]",
                    { "size-9 text-base": index === 1 }
                  )}>
                  <StarIcon className="size-full" />
                  <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-phonk">
                    {index === 0 && 2}
                    {index === 1 && 1}
                    {index === 2 && 3}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {/* bottom actions */}
      <div className="relative z-20 mt-1 flex items-center justify-end">
        <Button
          className={cn(
            "items-center justify-center rounded-full",
            "size-7 bg-transparent p-0 text-slate-500 hover:bg-slate-500/10"
          )}>
          <Share2Icon />
        </Button>
      </div>
    </div>
  );
};
