import React from "react";

import { cn } from "@/helpers/tailwind";

interface HypeMeterProps {
  percentage: number; // 0 - 100
  className?: string;
}

export const HypeMeter: React.FC<HypeMeterProps> = ({
  percentage,
  className,
}) => (
  <div
    className={cn(
      "relative flex h-3 w-full overflow-hidden rounded-full bg-slate-700 py-[2px]",
      className
    )}>
    <div
      className="absolute right-0 top-0 h-full bg-inherit"
      style={{ width: `${100 - Math.ceil(percentage)}%` }}
    />
    <div
      className={cn(
        "w-full bg-gradient-to-r from-white via-yellow-400 to-red-500"
      )}
    />
  </div>
);
