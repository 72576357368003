import { PagedDataResponse } from "@/types";
import { Battle } from "@dripit/types";

const artistNames = [
  "Drake",
  "The Weeknd",
  "Lil Baby",
  "Post Malone",
  "Travis Scott",
  "Franz Liszt",
  "Metallica",
  "Megadeth",
  "Eminem",
  "Kendrick Lamar",
  "Red Hot Chili Peppers",
  "Taylor Swift",
  "Ed Sheeran",
];

const userNames = [
  "John Doe",
  "Alfred E. Neuman",
  "Kevin Spacey",
  "Christian Bale",
  "Tom Cruise",
  "Diana Ross",
  "John Lennon",
  "Elvis Presley",
  "Michael Jackson",
  "Prince",
  "Jimi Hendrix",
];

export const mockUsers = Array.from({ length: 10 }, (_, index) => ({
  id: index,
  name: userNames[Math.floor(Math.random() * userNames.length)],
  image:
    Math.random() < 0.7
      ? "https://i.scdn.co/image/ab6761610000e5eb69ca98dd3083f1082d740e44"
      : null,
  artist: {
    id: 0,
    name: artistNames[Math.floor(Math.random() * artistNames.length)],
    image: "https://i.scdn.co/image/9ec531e3712e68c72ae3cf06f17e4055a615c4bf",
    stake: Math.random() * 100000,
  },
}));

export const battleActivityMock = Array.from({ length: 16 }, (_, index) => ({
  id: index,
  user: {
    name: userNames[Math.floor(Math.random() * userNames.length)],
    image:
      Math.random() < 0.7
        ? "https://i.scdn.co/image/ab6761610000e5eb69ca98dd3083f1082d740e44"
        : null,
  },
  artist: {
    id: 0,
    name: artistNames[Math.floor(Math.random() * artistNames.length)],
    image: "https://i.scdn.co/image/9ec531e3712e68c72ae3cf06f17e4055a615c4bf",
    stake: Math.random() * 100000,
  },
  activity: {
    currentStake: Math.random() * 312,
    raisedBet: Math.random() < 0.3 ? Math.random() * 79 : 0,
  },
  timestamp: 1726356712,
}));

export const mockGetBattleResponse: PagedDataResponse<Battle> = {
  results: [
    {
      id: 6,
      onchainId: 0,
      type: 1,
      name: "Who will rank #1 on Hip Hop/Trap Global Hypeboard in May 2025?",
      winner_id: null,
      total: "8000000",
      start_date: 1743465600,
      end_date: 1746057600,
      created_at: 1743112268,
      created_by: 1,
      refundable: false,
      hypeboard_id: 1,
      battleSides: [
        {
          id: 5,
          side_id: 1,
          battle_id: 6,
          artist_id: 16,
          amount: "6000000",
          artist: {
            id: 16,
            spotify_id: "3TVXtAsR1Inumwj472S9r4",
            name: "Drake",
            slug: "3tvxtasr1inumwj472s9r4-drake",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4293385d324db8558179afd9",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 310,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 1,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 311,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 2,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 312,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 3,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 6,
          side_id: 2,
          battle_id: 6,
          artist_id: 6,
          amount: "2000000",
          artist: {
            id: 6,
            spotify_id: "2YZyLoL8N0Wb9xBt1NhZWg",
            name: "Kendrick Lamar",
            slug: "2yzylol8n0wb9xbt1nhzwg-kendrick-lamar",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb39ba6dcd4355c03de0b50918",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 70,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 71,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 2,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 72,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 3,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 7,
          side_id: 3,
          battle_id: 6,
          artist_id: 5,
          amount: "0",
          artist: {
            id: 5,
            spotify_id: "7jVv8c5Fj3E9VhNjxT4snq",
            name: "Lil Nas X",
            slug: "7jvv8c5fj3e9vhnjxt4snq-lil-nas-x",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb3758a33e782b46bd7f174e1d",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 46,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 1,
                score: 100,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 47,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 2,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 48,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 3,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 8,
          side_id: 4,
          battle_id: 6,
          artist_id: 12,
          amount: "0",
          artist: {
            id: 12,
            spotify_id: "5f7VJjfbwm532GiveGC0ZK",
            name: "Lil Baby",
            slug: "5f7vjjfbwm532givegc0zk-lil-baby",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb514da623c55dc9d2155705dd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 214,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 1,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 215,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 2,
                score: 93,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 216,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 3,
                score: 92,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 9,
          side_id: 5,
          battle_id: 6,
          artist_id: 4,
          amount: "0",
          artist: {
            id: 4,
            spotify_id: "3wyVrVrFCkukjdVIdirGVY",
            name: "Lil Pump",
            slug: "3wyvrvrfckukjdvidirgvy-lil-pump",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6bc83a52ca96ea7e6ac30c7f",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 22,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 1,
                score: 52,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 23,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 24,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 3,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 10,
          side_id: 6,
          battle_id: 6,
          artist_id: 9,
          amount: "0",
          artist: {
            id: 9,
            spotify_id: "7dGJo4pcD2V6oG8kP0tJRR",
            name: "Eminem",
            slug: "7dgjo4pcd2v6og8kp0tjrr-eminem",
            image:
              "https://i.scdn.co/image/ab6761610000e5eba00b11c129b27a88fc72f36b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: "USA",
            hypemeter_scores: [
              {
                id: 142,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 1,
                score: 56,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 143,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 144,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 11,
          side_id: 7,
          battle_id: 6,
          artist_id: 13,
          amount: "0",
          artist: {
            id: 13,
            spotify_id: "1URnnhqYAYcrqrcwql10ft",
            name: "21 Savage",
            slug: "1urnnhqyaycrqrcwql10ft-21-savage",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4f8f76117470957c0e81e5b2",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 238,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 1,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 239,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 2,
                score: 96,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 240,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 3,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 12,
          side_id: 8,
          battle_id: 6,
          artist_id: 14,
          amount: "0",
          artist: {
            id: 14,
            spotify_id: "5cj0lLjcoR7YOSnhnX0Po5",
            name: "Doja Cat",
            slug: "5cj0lljcor7yosnhnx0po5-doja-cat",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe94f88ff74ae4ddcab961f97",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 262,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 263,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 2,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 264,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 3,
                score: 59,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 13,
          side_id: 9,
          battle_id: 6,
          artist_id: 23,
          amount: "0",
          artist: {
            id: 23,
            spotify_id: "5K4W6rqBFWDnAN6FQUkS6x",
            name: "Kanye West",
            slug: "5k4w6rqbfwdnan6fquks6x-kanye-west",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6e835a500e791bf9c27a422a",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 478,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 1,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 479,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 480,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 3,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 14,
          side_id: 10,
          battle_id: 6,
          artist_id: 21,
          amount: "0",
          artist: {
            id: 21,
            spotify_id: "46SHBwWsqBkxI7EeeBEQG7",
            name: "Kodak Black",
            slug: "46shbwwsqbkxi7eeebeqg7-kodak-black",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb70c05cf4dc9a7d3ffd02ba19",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 430,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 1,
                score: 66,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 431,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 2,
                score: 80,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 432,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 15,
          side_id: 11,
          battle_id: 6,
          artist_id: 24,
          amount: "0",
          artist: {
            id: 24,
            spotify_id: "1RyvyyTE3xzB2ZywiAwp0i",
            name: "Future",
            slug: "1ryvyyte3xzb2zywiawp0i-future",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb7565b356bc9d9394eefa2ccb",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 502,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 1,
                score: 91,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 503,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 504,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 16,
          side_id: 12,
          battle_id: 6,
          artist_id: 7,
          amount: "0",
          artist: {
            id: 7,
            spotify_id: "0Y5tJX1MQlPlqiwlOH1tJY",
            name: "Travis Scott",
            slug: "0y5tjx1mqlplqiwloh1tjy-travis-scott",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb19c2790744c792d05570bb71",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 94,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 1,
                score: 70,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 95,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 2,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 96,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 17,
          side_id: 13,
          battle_id: 6,
          artist_id: 15,
          amount: "0",
          artist: {
            id: 15,
            spotify_id: "0hCNtLu0JehylgoiP8L4Gh",
            name: "Nicki Minaj",
            slug: "0hcntlu0jehylgoip8l4gh-nicki-minaj",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb07a50f0a9a8f11e5a1102cbd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 286,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 1,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 287,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 2,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 288,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 18,
          side_id: 14,
          battle_id: 6,
          artist_id: 11,
          amount: "0",
          artist: {
            id: 11,
            spotify_id: "246dkjvS1zLTtiykXe5h60",
            name: "Post Malone",
            slug: "246dkjvs1zlttiykxe5h60-post-malone",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe17c0aa1714a03d62b5ce4e0",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 190,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 191,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 2,
                score: 82,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 192,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 19,
          side_id: 15,
          battle_id: 6,
          artist_id: 8,
          amount: "0",
          artist: {
            id: 8,
            spotify_id: "1bAftSH8umNcGZ0uyV7LMg",
            name: "Duki",
            slug: "1baftsh8umncgz0uyv7lmg-duki",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb0f682e8d99b232d621a25c3b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 118,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 1,
                score: 68,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 119,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 2,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 120,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 3,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 20,
          side_id: 16,
          battle_id: 6,
          artist_id: 22,
          amount: "0",
          artist: {
            id: 22,
            spotify_id: "7iZtZyCzp3LItcw1wtPI3D",
            name: "Rae Sremmurd",
            slug: "7iztzyczp3litcw1wtpi3d-rae-sremmurd",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebca1568791bf7ac0cf34dbc66",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 454,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 455,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 2,
                score: 64,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 456,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 3,
                score: 54,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 21,
          side_id: 17,
          battle_id: 6,
          artist_id: 19,
          amount: "0",
          artist: {
            id: 19,
            spotify_id: "4kYSro6naA4h99UJvo89HB",
            name: "Cardi B",
            slug: "4kysro6naa4h99ujvo89hb-cardi-b",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4e8a7e14e2f602eb9af24e31",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 382,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 1,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 383,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 2,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 384,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 22,
          side_id: 18,
          battle_id: 6,
          artist_id: 25,
          amount: "0",
          artist: {
            id: 25,
            spotify_id: "4MCBfE4596Uoi2O4DtmEMz",
            name: "Juice WRLD",
            slug: "4mcbfe4596uoi2o4dtmemz-juice-wrld",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb01ab50f1479f24a42804efd8",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 526,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 527,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 528,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 3,
                score: 97,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 23,
          side_id: 19,
          battle_id: 6,
          artist_id: 26,
          amount: "0",
          artist: {
            id: 26,
            spotify_id: "55Aa2cqylxrFIXC767Z865",
            name: "Lil Wayne",
            slug: "55aa2cqylxrfixc767z865-lil-wayne",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc1c08e541eae3cc82c6988c4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 550,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 551,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 552,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 3,
                score: 90,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 24,
          side_id: 20,
          battle_id: 6,
          artist_id: 27,
          amount: "0",
          artist: {
            id: 27,
            spotify_id: "20sxb77xiYeusSH8cVdatc",
            name: "Meek Mill",
            slug: "20sxb77xiyeussh8cvdatc-meek-mill",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc14756c27212bdea80f6cfac",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 574,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 1,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 575,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 576,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 25,
          side_id: 21,
          battle_id: 6,
          artist_id: 17,
          amount: "0",
          artist: {
            id: 17,
            spotify_id: "6vXTefBL93Dj5IqAWq6OTv",
            name: "French Montana",
            slug: "6vxtefbl93dj5iqawq6otv-french-montana",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebadb2f8e09c3e3171956aafe4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 334,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 1,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 335,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 2,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 336,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 3,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 26,
          side_id: 22,
          battle_id: 6,
          artist_id: 10,
          amount: "0",
          artist: {
            id: 10,
            spotify_id: "5LHRHt1k9lMyONurDHEdrp",
            name: "Tyga",
            slug: "5lhrht1k9lmyonurdhedrp-tyga",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc0aa5c92c47f7e766074edce",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 166,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 1,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 167,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 168,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        // {
        //   id: 27,
        //   side_id: 23,
        //   battle_id: 6,
        //   artist_id: null,
        //   amount: "0",
        //   artist: null,
        // },
        {
          id: 28,
          side_id: 24,
          battle_id: 6,
          artist_id: 18,
          amount: "0",
          artist: {
            id: 18,
            spotify_id: "7c0XG5cIJTrrAgEC3ULPiq",
            name: "Ty Dolla $ign",
            slug: "7c0xg5cijtrragec3ulpiq-ty-dolla-ign",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb24b3c0f33bc5e3f6a53f84d7",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 358,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 359,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 2,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 360,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 3,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 29,
          side_id: 25,
          battle_id: 6,
          artist_id: 20,
          amount: "0",
          artist: {
            id: 20,
            spotify_id: "15UsOTVnJzReFVN1VCnxy4",
            name: "XXXTENTACION",
            slug: "15usotvnjzrefvn1vcnxy4-xxxtentacion",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebf0c20db5ef6c6fbe5135d2e4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 406,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 407,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 2,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 408,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 3,
                score: 89,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
      ],
      comments: [],
    },
    {
      id: 8,
      onchainId: 2,
      type: 1,
      name: "Who will rank #1 on Hip Hop/Trap Global Hypeboard in Week 14?",
      winner_id: null,
      total: "24000000",
      start_date: 1743419850,
      end_date: 1743419860,
      created_at: 1743419746,
      created_by: 1,
      refundable: false,
      hypeboard_id: 1,
      battleSides: [
        {
          id: 55,
          side_id: 1,
          battle_id: 8,
          artist_id: 16,
          amount: "0",
          artist: {
            id: 16,
            spotify_id: "3TVXtAsR1Inumwj472S9r4",
            name: "Drake",
            slug: "3tvxtasr1inumwj472s9r4-drake",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4293385d324db8558179afd9",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 310,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 1,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 311,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 2,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 312,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 3,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 56,
          side_id: 2,
          battle_id: 8,
          artist_id: 6,
          amount: "20000000",
          artist: {
            id: 6,
            spotify_id: "2YZyLoL8N0Wb9xBt1NhZWg",
            name: "Kendrick Lamar",
            slug: "2yzylol8n0wb9xbt1nhzwg-kendrick-lamar",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb39ba6dcd4355c03de0b50918",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 70,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 71,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 2,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 72,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 3,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 57,
          side_id: 3,
          battle_id: 8,
          artist_id: 5,
          amount: "0",
          artist: {
            id: 5,
            spotify_id: "7jVv8c5Fj3E9VhNjxT4snq",
            name: "Lil Nas X",
            slug: "7jvv8c5fj3e9vhnjxt4snq-lil-nas-x",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb3758a33e782b46bd7f174e1d",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 46,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 1,
                score: 100,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 47,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 2,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 48,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 3,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 58,
          side_id: 4,
          battle_id: 8,
          artist_id: 12,
          amount: "4000000",
          artist: {
            id: 12,
            spotify_id: "5f7VJjfbwm532GiveGC0ZK",
            name: "Lil Baby",
            slug: "5f7vjjfbwm532givegc0zk-lil-baby",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb514da623c55dc9d2155705dd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 214,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 1,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 215,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 2,
                score: 93,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 216,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 3,
                score: 92,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 59,
          side_id: 5,
          battle_id: 8,
          artist_id: 4,
          amount: "0",
          artist: {
            id: 4,
            spotify_id: "3wyVrVrFCkukjdVIdirGVY",
            name: "Lil Pump",
            slug: "3wyvrvrfckukjdvidirgvy-lil-pump",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6bc83a52ca96ea7e6ac30c7f",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 22,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 1,
                score: 52,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 23,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 24,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 3,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 60,
          side_id: 6,
          battle_id: 8,
          artist_id: 9,
          amount: "0",
          artist: {
            id: 9,
            spotify_id: "7dGJo4pcD2V6oG8kP0tJRR",
            name: "Eminem",
            slug: "7dgjo4pcd2v6og8kp0tjrr-eminem",
            image:
              "https://i.scdn.co/image/ab6761610000e5eba00b11c129b27a88fc72f36b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: "USA",
            hypemeter_scores: [
              {
                id: 142,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 1,
                score: 56,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 143,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 144,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 61,
          side_id: 7,
          battle_id: 8,
          artist_id: 13,
          amount: "0",
          artist: {
            id: 13,
            spotify_id: "1URnnhqYAYcrqrcwql10ft",
            name: "21 Savage",
            slug: "1urnnhqyaycrqrcwql10ft-21-savage",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4f8f76117470957c0e81e5b2",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 238,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 1,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 239,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 2,
                score: 96,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 240,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 3,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 62,
          side_id: 8,
          battle_id: 8,
          artist_id: 14,
          amount: "0",
          artist: {
            id: 14,
            spotify_id: "5cj0lLjcoR7YOSnhnX0Po5",
            name: "Doja Cat",
            slug: "5cj0lljcor7yosnhnx0po5-doja-cat",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe94f88ff74ae4ddcab961f97",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 262,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 263,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 2,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 264,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 3,
                score: 59,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 63,
          side_id: 9,
          battle_id: 8,
          artist_id: 23,
          amount: "0",
          artist: {
            id: 23,
            spotify_id: "5K4W6rqBFWDnAN6FQUkS6x",
            name: "Kanye West",
            slug: "5k4w6rqbfwdnan6fquks6x-kanye-west",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6e835a500e791bf9c27a422a",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 478,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 1,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 479,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 480,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 3,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 64,
          side_id: 10,
          battle_id: 8,
          artist_id: 21,
          amount: "0",
          artist: {
            id: 21,
            spotify_id: "46SHBwWsqBkxI7EeeBEQG7",
            name: "Kodak Black",
            slug: "46shbwwsqbkxi7eeebeqg7-kodak-black",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb70c05cf4dc9a7d3ffd02ba19",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 430,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 1,
                score: 66,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 431,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 2,
                score: 80,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 432,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 65,
          side_id: 11,
          battle_id: 8,
          artist_id: 24,
          amount: "0",
          artist: {
            id: 24,
            spotify_id: "1RyvyyTE3xzB2ZywiAwp0i",
            name: "Future",
            slug: "1ryvyyte3xzb2zywiawp0i-future",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb7565b356bc9d9394eefa2ccb",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 502,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 1,
                score: 91,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 503,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 504,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 66,
          side_id: 12,
          battle_id: 8,
          artist_id: 7,
          amount: "0",
          artist: {
            id: 7,
            spotify_id: "0Y5tJX1MQlPlqiwlOH1tJY",
            name: "Travis Scott",
            slug: "0y5tjx1mqlplqiwloh1tjy-travis-scott",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb19c2790744c792d05570bb71",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 94,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 1,
                score: 70,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 95,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 2,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 96,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 67,
          side_id: 13,
          battle_id: 8,
          artist_id: 15,
          amount: "0",
          artist: {
            id: 15,
            spotify_id: "0hCNtLu0JehylgoiP8L4Gh",
            name: "Nicki Minaj",
            slug: "0hcntlu0jehylgoip8l4gh-nicki-minaj",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb07a50f0a9a8f11e5a1102cbd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 286,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 1,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 287,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 2,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 288,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 68,
          side_id: 14,
          battle_id: 8,
          artist_id: 11,
          amount: "0",
          artist: {
            id: 11,
            spotify_id: "246dkjvS1zLTtiykXe5h60",
            name: "Post Malone",
            slug: "246dkjvs1zlttiykxe5h60-post-malone",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe17c0aa1714a03d62b5ce4e0",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 190,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 191,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 2,
                score: 82,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 192,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 69,
          side_id: 15,
          battle_id: 8,
          artist_id: 8,
          amount: "0",
          artist: {
            id: 8,
            spotify_id: "1bAftSH8umNcGZ0uyV7LMg",
            name: "Duki",
            slug: "1baftsh8umncgz0uyv7lmg-duki",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb0f682e8d99b232d621a25c3b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 118,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 1,
                score: 68,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 119,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 2,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 120,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 3,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 70,
          side_id: 16,
          battle_id: 8,
          artist_id: 22,
          amount: "0",
          artist: {
            id: 22,
            spotify_id: "7iZtZyCzp3LItcw1wtPI3D",
            name: "Rae Sremmurd",
            slug: "7iztzyczp3litcw1wtpi3d-rae-sremmurd",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebca1568791bf7ac0cf34dbc66",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 454,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 455,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 2,
                score: 64,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 456,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 3,
                score: 54,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 71,
          side_id: 17,
          battle_id: 8,
          artist_id: 19,
          amount: "0",
          artist: {
            id: 19,
            spotify_id: "4kYSro6naA4h99UJvo89HB",
            name: "Cardi B",
            slug: "4kysro6naa4h99ujvo89hb-cardi-b",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4e8a7e14e2f602eb9af24e31",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 382,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 1,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 383,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 2,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 384,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 72,
          side_id: 18,
          battle_id: 8,
          artist_id: 25,
          amount: "0",
          artist: {
            id: 25,
            spotify_id: "4MCBfE4596Uoi2O4DtmEMz",
            name: "Juice WRLD",
            slug: "4mcbfe4596uoi2o4dtmemz-juice-wrld",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb01ab50f1479f24a42804efd8",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 526,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 527,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 528,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 3,
                score: 97,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 73,
          side_id: 19,
          battle_id: 8,
          artist_id: 26,
          amount: "0",
          artist: {
            id: 26,
            spotify_id: "55Aa2cqylxrFIXC767Z865",
            name: "Lil Wayne",
            slug: "55aa2cqylxrfixc767z865-lil-wayne",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc1c08e541eae3cc82c6988c4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 550,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 551,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 552,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 3,
                score: 90,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 74,
          side_id: 20,
          battle_id: 8,
          artist_id: 27,
          amount: "0",
          artist: {
            id: 27,
            spotify_id: "20sxb77xiYeusSH8cVdatc",
            name: "Meek Mill",
            slug: "20sxb77xiyeussh8cvdatc-meek-mill",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc14756c27212bdea80f6cfac",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 574,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 1,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 575,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 576,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 75,
          side_id: 21,
          battle_id: 8,
          artist_id: 17,
          amount: "0",
          artist: {
            id: 17,
            spotify_id: "6vXTefBL93Dj5IqAWq6OTv",
            name: "French Montana",
            slug: "6vxtefbl93dj5iqawq6otv-french-montana",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebadb2f8e09c3e3171956aafe4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 334,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 1,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 335,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 2,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 336,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 3,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 76,
          side_id: 22,
          battle_id: 8,
          artist_id: 10,
          amount: "0",
          artist: {
            id: 10,
            spotify_id: "5LHRHt1k9lMyONurDHEdrp",
            name: "Tyga",
            slug: "5lhrht1k9lmyonurdhedrp-tyga",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc0aa5c92c47f7e766074edce",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 166,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 1,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 167,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 168,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        // {
        //   id: 77,
        //   side_id: 23,
        //   battle_id: 8,
        //   artist_id: null,
        //   amount: "0",
        //   artist: null,
        // },
        {
          id: 78,
          side_id: 24,
          battle_id: 8,
          artist_id: 18,
          amount: "0",
          artist: {
            id: 18,
            spotify_id: "7c0XG5cIJTrrAgEC3ULPiq",
            name: "Ty Dolla $ign",
            slug: "7c0xg5cijtrragec3ulpiq-ty-dolla-ign",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb24b3c0f33bc5e3f6a53f84d7",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 358,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 359,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 2,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 360,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 3,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 79,
          side_id: 25,
          battle_id: 8,
          artist_id: 20,
          amount: "0",
          artist: {
            id: 20,
            spotify_id: "15UsOTVnJzReFVN1VCnxy4",
            name: "XXXTENTACION",
            slug: "15usotvnjzrefvn1vcnxy4-xxxtentacion",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebf0c20db5ef6c6fbe5135d2e4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 406,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 407,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 2,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 408,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 3,
                score: 89,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
      ],
      comments: [],
    },
    {
      id: 7,
      onchainId: 1,
      type: 1,
      name: "Who will rank #1 on Hip Hop/Trap Global Hypeboard in Week 5?",
      winner_id: null,
      total: "0",
      start_date: 1743113850,
      end_date: 1743638400,
      created_at: 1743113843,
      created_by: 1,
      refundable: false,
      hypeboard_id: 1,
      battleSides: [
        {
          id: 30,
          side_id: 1,
          battle_id: 7,
          artist_id: 16,
          amount: "0",
          artist: {
            id: 16,
            spotify_id: "3TVXtAsR1Inumwj472S9r4",
            name: "Drake",
            slug: "3tvxtasr1inumwj472s9r4-drake",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4293385d324db8558179afd9",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 310,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 1,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 311,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 2,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 312,
                artist_id: 16,
                hypeboard_artist_id: 13,
                timeFrame: 3,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 31,
          side_id: 2,
          battle_id: 7,
          artist_id: 6,
          amount: "0",
          artist: {
            id: 6,
            spotify_id: "2YZyLoL8N0Wb9xBt1NhZWg",
            name: "Kendrick Lamar",
            slug: "2yzylol8n0wb9xbt1nhzwg-kendrick-lamar",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb39ba6dcd4355c03de0b50918",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 70,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 71,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 2,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 72,
                artist_id: 6,
                hypeboard_artist_id: 3,
                timeFrame: 3,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 32,
          side_id: 3,
          battle_id: 7,
          artist_id: 5,
          amount: "0",
          artist: {
            id: 5,
            spotify_id: "7jVv8c5Fj3E9VhNjxT4snq",
            name: "Lil Nas X",
            slug: "7jvv8c5fj3e9vhnjxt4snq-lil-nas-x",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb3758a33e782b46bd7f174e1d",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 46,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 1,
                score: 100,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 47,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 2,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 48,
                artist_id: 5,
                hypeboard_artist_id: 2,
                timeFrame: 3,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 33,
          side_id: 4,
          battle_id: 7,
          artist_id: 12,
          amount: "0",
          artist: {
            id: 12,
            spotify_id: "5f7VJjfbwm532GiveGC0ZK",
            name: "Lil Baby",
            slug: "5f7vjjfbwm532givegc0zk-lil-baby",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb514da623c55dc9d2155705dd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 214,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 1,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 215,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 2,
                score: 93,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 216,
                artist_id: 12,
                hypeboard_artist_id: 9,
                timeFrame: 3,
                score: 92,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 34,
          side_id: 5,
          battle_id: 7,
          artist_id: 4,
          amount: "0",
          artist: {
            id: 4,
            spotify_id: "3wyVrVrFCkukjdVIdirGVY",
            name: "Lil Pump",
            slug: "3wyvrvrfckukjdvidirgvy-lil-pump",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6bc83a52ca96ea7e6ac30c7f",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 22,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 1,
                score: 52,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 23,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 24,
                artist_id: 4,
                hypeboard_artist_id: 1,
                timeFrame: 3,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 35,
          side_id: 6,
          battle_id: 7,
          artist_id: 9,
          amount: "0",
          artist: {
            id: 9,
            spotify_id: "7dGJo4pcD2V6oG8kP0tJRR",
            name: "Eminem",
            slug: "7dgjo4pcd2v6og8kp0tjrr-eminem",
            image:
              "https://i.scdn.co/image/ab6761610000e5eba00b11c129b27a88fc72f36b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: "USA",
            hypemeter_scores: [
              {
                id: 142,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 1,
                score: 56,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 143,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 144,
                artist_id: 9,
                hypeboard_artist_id: 6,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 36,
          side_id: 7,
          battle_id: 7,
          artist_id: 13,
          amount: "0",
          artist: {
            id: 13,
            spotify_id: "1URnnhqYAYcrqrcwql10ft",
            name: "21 Savage",
            slug: "1urnnhqyaycrqrcwql10ft-21-savage",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4f8f76117470957c0e81e5b2",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 238,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 1,
                score: 73,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 239,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 2,
                score: 96,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 240,
                artist_id: 13,
                hypeboard_artist_id: 10,
                timeFrame: 3,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 37,
          side_id: 8,
          battle_id: 7,
          artist_id: 14,
          amount: "0",
          artist: {
            id: 14,
            spotify_id: "5cj0lLjcoR7YOSnhnX0Po5",
            name: "Doja Cat",
            slug: "5cj0lljcor7yosnhnx0po5-doja-cat",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe94f88ff74ae4ddcab961f97",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 262,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 263,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 2,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 264,
                artist_id: 14,
                hypeboard_artist_id: 11,
                timeFrame: 3,
                score: 59,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 38,
          side_id: 9,
          battle_id: 7,
          artist_id: 23,
          amount: "0",
          artist: {
            id: 23,
            spotify_id: "5K4W6rqBFWDnAN6FQUkS6x",
            name: "Kanye West",
            slug: "5k4w6rqbfwdnan6fquks6x-kanye-west",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb6e835a500e791bf9c27a422a",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 478,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 1,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 479,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 2,
                score: 63,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 480,
                artist_id: 23,
                hypeboard_artist_id: 20,
                timeFrame: 3,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 39,
          side_id: 10,
          battle_id: 7,
          artist_id: 21,
          amount: "0",
          artist: {
            id: 21,
            spotify_id: "46SHBwWsqBkxI7EeeBEQG7",
            name: "Kodak Black",
            slug: "46shbwwsqbkxi7eeebeqg7-kodak-black",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb70c05cf4dc9a7d3ffd02ba19",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 430,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 1,
                score: 66,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 431,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 2,
                score: 80,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 432,
                artist_id: 21,
                hypeboard_artist_id: 18,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 40,
          side_id: 11,
          battle_id: 7,
          artist_id: 24,
          amount: "0",
          artist: {
            id: 24,
            spotify_id: "1RyvyyTE3xzB2ZywiAwp0i",
            name: "Future",
            slug: "1ryvyyte3xzb2zywiawp0i-future",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb7565b356bc9d9394eefa2ccb",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 502,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 1,
                score: 91,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 503,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 504,
                artist_id: 24,
                hypeboard_artist_id: 21,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 41,
          side_id: 12,
          battle_id: 7,
          artist_id: 7,
          amount: "0",
          artist: {
            id: 7,
            spotify_id: "0Y5tJX1MQlPlqiwlOH1tJY",
            name: "Travis Scott",
            slug: "0y5tjx1mqlplqiwloh1tjy-travis-scott",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb19c2790744c792d05570bb71",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 94,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 1,
                score: 70,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 95,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 2,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 96,
                artist_id: 7,
                hypeboard_artist_id: 4,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 42,
          side_id: 13,
          battle_id: 7,
          artist_id: 15,
          amount: "0",
          artist: {
            id: 15,
            spotify_id: "0hCNtLu0JehylgoiP8L4Gh",
            name: "Nicki Minaj",
            slug: "0hcntlu0jehylgoip8l4gh-nicki-minaj",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb07a50f0a9a8f11e5a1102cbd",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 286,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 1,
                score: 57,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 287,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 2,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 288,
                artist_id: 15,
                hypeboard_artist_id: 12,
                timeFrame: 3,
                score: 79,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 43,
          side_id: 14,
          battle_id: 7,
          artist_id: 11,
          amount: "0",
          artist: {
            id: 11,
            spotify_id: "246dkjvS1zLTtiykXe5h60",
            name: "Post Malone",
            slug: "246dkjvs1zlttiykxe5h60-post-malone",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebe17c0aa1714a03d62b5ce4e0",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 190,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 191,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 2,
                score: 82,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 192,
                artist_id: 11,
                hypeboard_artist_id: 8,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 44,
          side_id: 15,
          battle_id: 7,
          artist_id: 8,
          amount: "0",
          artist: {
            id: 8,
            spotify_id: "1bAftSH8umNcGZ0uyV7LMg",
            name: "Duki",
            slug: "1baftsh8umncgz0uyv7lmg-duki",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb0f682e8d99b232d621a25c3b",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 118,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 1,
                score: 68,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 119,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 2,
                score: 75,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 120,
                artist_id: 8,
                hypeboard_artist_id: 5,
                timeFrame: 3,
                score: 76,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 45,
          side_id: 16,
          battle_id: 7,
          artist_id: 22,
          amount: "0",
          artist: {
            id: 22,
            spotify_id: "7iZtZyCzp3LItcw1wtPI3D",
            name: "Rae Sremmurd",
            slug: "7iztzyczp3litcw1wtpi3d-rae-sremmurd",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebca1568791bf7ac0cf34dbc66",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 454,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 1,
                score: 83,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 455,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 2,
                score: 64,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 456,
                artist_id: 22,
                hypeboard_artist_id: 19,
                timeFrame: 3,
                score: 54,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 46,
          side_id: 17,
          battle_id: 7,
          artist_id: 19,
          amount: "0",
          artist: {
            id: 19,
            spotify_id: "4kYSro6naA4h99UJvo89HB",
            name: "Cardi B",
            slug: "4kysro6naa4h99ujvo89hb-cardi-b",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb4e8a7e14e2f602eb9af24e31",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 382,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 1,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 383,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 2,
                score: 61,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 384,
                artist_id: 19,
                hypeboard_artist_id: 16,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 47,
          side_id: 18,
          battle_id: 7,
          artist_id: 25,
          amount: "0",
          artist: {
            id: 25,
            spotify_id: "4MCBfE4596Uoi2O4DtmEMz",
            name: "Juice WRLD",
            slug: "4mcbfe4596uoi2o4dtmemz-juice-wrld",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb01ab50f1479f24a42804efd8",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 526,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 1,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 527,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 2,
                score: 88,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 528,
                artist_id: 25,
                hypeboard_artist_id: 22,
                timeFrame: 3,
                score: 97,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 48,
          side_id: 19,
          battle_id: 7,
          artist_id: 26,
          amount: "0",
          artist: {
            id: 26,
            spotify_id: "55Aa2cqylxrFIXC767Z865",
            name: "Lil Wayne",
            slug: "55aa2cqylxrfixc767z865-lil-wayne",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc1c08e541eae3cc82c6988c4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 550,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 1,
                score: 55,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 551,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 552,
                artist_id: 26,
                hypeboard_artist_id: 23,
                timeFrame: 3,
                score: 90,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 49,
          side_id: 20,
          battle_id: 7,
          artist_id: 27,
          amount: "0",
          artist: {
            id: 27,
            spotify_id: "20sxb77xiYeusSH8cVdatc",
            name: "Meek Mill",
            slug: "20sxb77xiyeussh8cvdatc-meek-mill",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc14756c27212bdea80f6cfac",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 574,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 1,
                score: 71,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 575,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 2,
                score: 51,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 576,
                artist_id: 27,
                hypeboard_artist_id: 24,
                timeFrame: 3,
                score: 50,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 50,
          side_id: 21,
          battle_id: 7,
          artist_id: 17,
          amount: "0",
          artist: {
            id: 17,
            spotify_id: "6vXTefBL93Dj5IqAWq6OTv",
            name: "French Montana",
            slug: "6vxtefbl93dj5iqawq6otv-french-montana",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebadb2f8e09c3e3171956aafe4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 334,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 1,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 335,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 2,
                score: 62,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 336,
                artist_id: 17,
                hypeboard_artist_id: 14,
                timeFrame: 3,
                score: 98,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 51,
          side_id: 22,
          battle_id: 7,
          artist_id: 10,
          amount: "0",
          artist: {
            id: 10,
            spotify_id: "5LHRHt1k9lMyONurDHEdrp",
            name: "Tyga",
            slug: "5lhrht1k9lmyonurdhedrp-tyga",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebc0aa5c92c47f7e766074edce",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 166,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 1,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 167,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 2,
                score: 72,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 168,
                artist_id: 10,
                hypeboard_artist_id: 7,
                timeFrame: 3,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        // {
        //   id: 52,
        //   side_id: 23,
        //   battle_id: 7,
        //   artist_id: null,
        //   amount: "0",
        //   artist: null,
        // },
        {
          id: 53,
          side_id: 24,
          battle_id: 7,
          artist_id: 18,
          amount: "0",
          artist: {
            id: 18,
            spotify_id: "7c0XG5cIJTrrAgEC3ULPiq",
            name: "Ty Dolla $ign",
            slug: "7c0xg5cijtrragec3ulpiq-ty-dolla-ign",
            image:
              "https://i.scdn.co/image/ab6761610000e5eb24b3c0f33bc5e3f6a53f84d7",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 358,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 359,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 2,
                score: 95,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 360,
                artist_id: 18,
                hypeboard_artist_id: 15,
                timeFrame: 3,
                score: 65,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
        {
          id: 54,
          side_id: 25,
          battle_id: 7,
          artist_id: 20,
          amount: "0",
          artist: {
            id: 20,
            spotify_id: "15UsOTVnJzReFVN1VCnxy4",
            name: "XXXTENTACION",
            slug: "15usotvnjzrefvn1vcnxy4-xxxtentacion",
            image:
              "https://i.scdn.co/image/ab6761610000e5ebf0c20db5ef6c6fbe5135d2e4",
            bio: null,
            wins_count: 0,
            losses_count: 0,
            updated_at: 1743199705,
            country: null,
            hypemeter_scores: [
              {
                id: 406,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 1,
                score: 60,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 407,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 2,
                score: 77,
                date: "2025-03-30T00:00:00.000Z",
              },
              {
                id: 408,
                artist_id: 20,
                hypeboard_artist_id: 17,
                timeFrame: 3,
                score: 89,
                date: "2025-03-30T00:00:00.000Z",
              },
            ],
          },
        },
      ],
      comments: [],
    },
  ],
  total_items: 3,
  current_page: 1,
  limit: 10,
  total_pages: 1,
};
