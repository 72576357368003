import { HypeboardArtist, HypeboardTimeframe, Timeframe } from "@dripit/types";
import isEmpty from "lodash.isempty";

export interface RankedHypeboardArtist extends HypeboardArtist {
  timeFrames: (Timeframe & { rank: number })[];
}

export const getRankedArtists = (
  artists?: HypeboardArtist[]
): RankedHypeboardArtist[] => {
  if (!artists || isEmpty(artists)) return [];
  const tfs = [
    HypeboardTimeframe["7D"],
    HypeboardTimeframe["30D"],
    HypeboardTimeframe["YTD"],
  ];

  // First, sort artists for each timeframe
  const sortedArtistsByTimeframe = tfs.map((timeframe) =>
    [...artists].sort(
      (a, b) =>
        (b.timeFrames?.find((t) => t.timeFrame === timeframe)?.score ?? 0) -
        (a.timeFrames?.find((t) => t.timeFrame === timeframe)?.score ?? 0)
    )
  );

  // Then, map each artist to add their ranks from each timeframe
  return artists.map((artist) => {
    const rankedTimeFrames = (artist.timeFrames ?? []).map((tf) => {
      const timeframeIndex = tfs.indexOf(tf.timeFrame);
      const rank =
        sortedArtistsByTimeframe[timeframeIndex].findIndex(
          (a) => a.id === artist.id
        ) + 1;
      return { ...tf, rank };
    });

    return { ...artist, timeFrames: rankedTimeFrames };
  });
};
