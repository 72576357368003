import React from "react";
import { useNavigate } from "react-router-dom";
import { HypeboardTimeframe } from "@dripit/types";

import { useHypeboards } from "@/api/hypeboardQueries";
import { HypeBoardCard, SearchInput } from "./components";
import { PageWrapper } from "@/components/layout";
import isEmpty from "lodash.isempty";
import { CenteredMessage } from "@/components/main";
import { Skeleton } from "@/components/ui";
import { useTranslate } from "@/hooks";
import { GaugeIcon } from "lucide-react";
import { cn } from "@/helpers/tailwind";

const HypeBoardsPage: React.FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { data, status, error, refetch } = useHypeboards();

  const handleSearch = (value: string) => {
    console.log({ mstatus: "Search now", value });
    refetch();
  };

  const renderContent = () => {
    if (status === "pending")
      return (
        <div className="mt-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
          {Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-3xl bg-slate-800 p-6">
              <div className="flex items-center justify-between gap-2">
                <Skeleton className="h-6 w-[75%] bg-slate-200/30" />
                <Skeleton className="h-6 w-[20%] bg-slate-200/30" />
              </div>
              <div className="mt-4 flex flex-col gap-2">
                <Skeleton className="mb-2 h-4 w-[20%] bg-slate-200/30" />
                <Skeleton className="h-3 w-[35%] bg-slate-200/30" />
                <Skeleton className="h-3 w-[35%] bg-slate-200/30" />
                <Skeleton className="h-3 w-[35%] bg-slate-200/30" />
                <Skeleton className="h-3 w-[35%] bg-slate-200/30" />
                <Skeleton className="h-3 w-[35%] bg-slate-200/30" />
              </div>
            </div>
          ))}
        </div>
      );
    if (error)
      return <CenteredMessage message={t("battles.error")} className="mt-16" />;
    if (isEmpty(data))
      return (
        <div className="mx-auto flex flex-col items-center justify-center gap-3 px-4 pt-12">
          <GaugeIcon />
          <p className="text-center">No active hypeboards at the moment.</p>
        </div>
      );

    return (
      <div className="mt-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
        {data?.map(({ id, name, artists }, index) => (
          <HypeBoardCard
            key={id}
            title={name}
            artists={artists}
            hidePattern={index % 2 === 1}
            sortingTimeframe={HypeboardTimeframe["7D"]}
            onClick={() => navigate(`/hype-boards/${id}`)}
          />
        ))}
      </div>
    );
  };

  return (
    <PageWrapper>
      <div className="mb-4 flex items-center justify-between gap-3">
        <h1 className="hidden font-phonk text-3xl md:block">Hypeboards</h1>
        <SearchInput
          placeholder="Search hypeboards..."
          className={cn("transition-opacity duration-200 md:max-w-[300px]", {
            "pointer-events-none opacity-0":
              status === "success" && isEmpty(data),
          })}
          disabled={status === "pending"}
          onSearch={handleSearch}
        />
      </div>
      {renderContent()}
    </PageWrapper>
  );
};

export default HypeBoardsPage;
